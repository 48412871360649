import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import useAlert from "../../hooks/useAlert";
import { addUsers } from "../../services/users";
import { alertSuccess } from "../../utils/alert";

type CreateUserDialogProps = {
  open: boolean;
  handleClose: () => void;
  mutate: () => void;
};

const initialValues = {
  full_name: null,
  contact_number: null,
  email: null,
  password: null,
};

const CreateUserSchema = Yup.object().shape({
  full_name: Yup.string().required("Please enter contact full name"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter contact email address"),
  password: Yup.string()
    .required("Please enter a password")
    .min(6, "Password must be at least 6 characters long"),
});

const CreateUserDialog = React.forwardRef<
  HTMLDivElement,
  CreateUserDialogProps
>(function CreateUserDialog(props, ref) {
  const { open, handleClose, mutate } = props;

  const { setAlertInfo } = useAlert();

  const [isLoading, setisLoading] = useState(false);

  const [submittedData, setSubmittedData] = useState<any>(null);

  const handleFormSubmit = async (values: any) => {
    setisLoading(true);

    await addUsers({
      users: [
        {
          name: values.full_name,
          email: values.email.toLowerCase(),
          password: values.password,
        },
      ],
      onSuccess: () => {
        mutate();
        handleClose();
      },
      setAlertInfo,
    });

    setisLoading(false);
  };
  return (
    <Modal ref={ref} open={open}>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={CreateUserSchema}
          onSubmit={handleFormSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  maxWidth: "80%",
                  width: 350,
                  backgroundColor: "background.paper",
                  borderRadius: 2,
                  paddingX: 4,
                  paddingY: 4,
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Box sx={{ marginBottom: 2, display: "flex" }}>
                  <Typography variant="h6" sx={{ color: "text.primary" }}>
                    Add New User
                  </Typography>
                </Box>

                <Box
                  sx={{
                    mt: 4,
                    mb: 5,
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                  }}
                >
                  <Field id="full_name" name="full_name">
                    {({ }) => (
                      <TextField
                        variant="standard"
                        size="small"
                        fullWidth
                        id="full_name"
                        name="full_name"
                        label="Full Name *"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.full_name}
                        placeholder="e.g. John Doe"
                        InputLabelProps={{ shrink: true }}
                        helperText={
                          errors?.full_name &&
                          touched?.full_name &&
                          String(errors?.full_name)
                        }
                        error={Boolean(errors?.full_name && touched?.full_name)}
                      />
                    )}
                  </Field>

                  <Field id="email" name="email">
                    {({ }) => (
                      <TextField
                        variant="standard"
                        size="small"
                        fullWidth
                        id="email"
                        name="email"
                        label="Email *"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        placeholder="user@example.com"
                        InputLabelProps={{ shrink: true }}
                        helperText={
                          errors?.email &&
                          touched?.email &&
                          String(errors?.email)
                        }
                        error={Boolean(errors?.email && touched?.email)}
                      />
                    )}
                  </Field>

                  <Field id="password" name="password">
                    {({ }) => (
                      <TextField
                        variant="standard"
                        size="small"
                        fullWidth
                        id="password"
                        name="password"
                        type="password"
                        label="Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        InputLabelProps={{ shrink: true }}
                        helperText={
                          errors?.password &&
                          touched?.password &&
                          String(errors?.password)
                        }
                        error={Boolean(
                          errors?.password && touched?.password
                        )}
                      />
                    )}
                  </Field>
                </Box>

                <Box sx={{ display: "flex", marginTop: 2 }}>
                  <Button
                    fullWidth
                    size="small"
                    disabled={isLoading}
                    variant="outlined"
                    disableElevation
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Box width={32} />
                  <Button
                    fullWidth
                    size="small"
                    disabled={isLoading}
                    variant="contained"
                    disableElevation
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
});

export default CreateUserDialog;
