import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import PersonIcon from "@mui/icons-material/Person";
import StorageIcon from "@mui/icons-material/Storage";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import { LinkType } from "../types/link";
import UserAvatarIconButton from "../layout/dashboard/UserAvatarIconButton";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DescriptionIcon from '@mui/icons-material/Description';
import CategoryIcon from '@mui/icons-material/Category';
import { title } from "process";

export const SIDEBAR_OBJECT = {
  dashboard: {
    title: "Dashboard",
    href: "/dashboard",
    icon: <GridViewRoundedIcon sx={{ fontSize: 20 }} />,
    isAdmin: false,
  },
  avatar: {
    title: "Profile",
    href: "/setting",
    icon: <UserAvatarIconButton />,
    isAdmin: false,
  },
  users: {
    title: "Users",
    href: "/users",
    icon: <PersonIcon sx={{ fontSize: 20 }} />,
    isAdmin: true,
  },
  customers: {
    title: "Customers",
    href: "/customers",
    icon: <GroupRoundedIcon sx={{ fontSize: 20 }} />,
    isAdmin: false,
  },
  jobs: {
    title: "Jobs",
    href: "/jobs",
    icon: <AccessTimeFilledIcon sx={{ fontSize: 20 }} />,
    isAdmin: false,
  },
  storages: {
    title: "Storages",
    href: "/storages",
    icon: <StorageIcon sx={{ fontSize: 20 }} />,
    isAdmin: false,
  },
  d365_reports: {
    title: "D365 Reports",
    href: "/d365_reports",
    icon: <MicrosoftIcon sx={{ fontSize: 20 }} />,
    isAdmin: false,
  },
  logs: {
    title: "Logs",
    href: "/logs",
    icon: <ReceiptIcon sx={{ fontSize: 20 }} />,
    isAdmin: false,
  },
  contracts: {
    title: "Contracts",
    href: "/contracts",
    icon: <DescriptionIcon sx={{ fontSize: 20 }} />,
    isAdmin: false,
  },
  installed_products: {
    title: "Installed Products",
    href: "/installed_products",
    icon: <CategoryIcon sx={{ fontSize: 20 }} />,
    isAdmin: false,
  },
  // logout: {
  //   title: "Logout",
  //   icon: <ExitToAppIcon sx={{ fontSize: 20 }} />,
  //   href: "/login",
  //   isAdmin: false,
  // },
};

export const SIDEBAR_LINKS: LinkType[] = Object.values(SIDEBAR_OBJECT).filter(
  (object) => !["Dashboard"].includes(object.title)
);
