import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  ButtonBase,
} from "@mui/material";
import { LinkType } from "../../types/link";
import { userInfo } from "os";
import useAuth from "../../hooks/useAuth";
import { Link as RouterLink } from "react-router-dom";

type DrawerListItemProps = {
  selected: boolean;
  isDrawerMinimized: boolean;
  link: LinkType;
};

const DrawerListItem = ({
  selected,
  isDrawerMinimized,
  link,
}: DrawerListItemProps) => {
  const { user } = useAuth();
  if (link.isAdmin && user?.role !== "Admin") return null;
  return (
    <Tooltip title={isDrawerMinimized ? link.title : ""} placement="right">
      <ListItem disablePadding sx={{}}>
        <ListItemButton
          sx={{
            height: 45,
            transition: "none",
            backgroundColor: selected ? "primary.main" : "background.paper",
            color: selected ? "#fff" : "text.primary",
            borderRadius: 2,
            ":hover": {
              backgroundColor: selected ? "primary.main" : "background.paper",
              color: selected ? "#fff" : "text.primary",
            },
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
          component={RouterLink}
          to={link.href}
        >
          <ListItemIcon
            sx={{
              color: selected ? "#fff" : "text.primary",
              minWidth: 0,
            }}
          >
            {link.icon}
          </ListItemIcon>

          {!isDrawerMinimized && (
            <ListItemText
              primary={
                <Typography sx={{ fontSize: 14 }}>{link.title}</Typography>
              }
            />
          )}
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};

export default DrawerListItem;
