import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  D365Account,
  D365EmailRecipient,
  D365Reports,
} from "../interfaces/d365";
import {
  deleteD365EmailRecipient,
  runReports,
  useAllD365Accounts,
  useAllD365EmailRecipients,
} from "../services/d365";
import D365AccountCard from "../components/D365/D365AccountCard";
import D365EmailRecipientTable from "../components/D365/D365EmailRecipientTable";
import useModal from "../hooks/useModal";
import D365EmailRecipientForm from "../components/D365/D365EmailRecipientForm";
import D365EmailRecipientDialog from "../components/D365/D365EmailRecipientDialog";
import useAlert from "../hooks/useAlert";
import RunSelectedButton from "../components/D365/RunSelectedButton";
import api from "../services/api";

const initialState = {
  id: "-1",
  email: "",
  is_active: true,
};

const D365Page = () => {
  const { setAlertInfo } = useAlert();
  const [accounts, setAccounts] = useState<D365Account[]>([]);
  const [emailRecipients, setEmailRecipients] = useState<D365EmailRecipient[]>(
    []
  );
  const [selectRecipient, setSelectedRecipient] =
    useState<D365EmailRecipient>(initialState);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [reports, setReports] = useState<D365Reports[]>([
    {
      name: "My Info Logs",
      is_selected: false,
    },
    {
      name: "Portal logins",
      is_selected: false,
    },
    {
      name: "Portal page views",
      is_selected: false,
    },
    {
      name: "Encodian Actions",
      is_selected: false,
    },
  ]);

  const { open, handleOpen, handleClose } = useModal();

  const {
    open: openConfirmationDialog,
    handleOpen: handleOpenConfirmationDialog,
    handleClose: handleCloseConfirmationDialog,
  } = useModal();

  const handleRunReports = async () => {
    const selectedReports = reports
      .filter((r) => r.is_selected)
      .map((r) => r.name);
    await runReports({
      body: { report_list: selectedReports },
      setAlertInfo,
    });
  };

  const handleEditRecipient = (
    recipient: D365EmailRecipient,
    isDelete: boolean = false
  ) => {
    setSelectedRecipient(recipient);
    isDelete ? handleOpenConfirmationDialog() : handleOpen();
  };
  const handleDelete = async (recipientId: string) => {
    if (recipientId === "-1") return;

    setIsDeleting(true);

    await deleteD365EmailRecipient({
      recipientId,
      onSuccess: () => {
        handleCloseConfirmationDialog();
        recipientMutate();
      },
      setAlertInfo,
    });

    setIsDeleting(false);
  };

  const {
    data: accountsData,
    mutate: accountsMutate,
    isLoading: accountsIsLoading,
  } = useAllD365Accounts();

  const {
    data: emailRecipientsData,
    mutate: recipientMutate,
    isLoading: emailRecipientsIsLoading,
  } = useAllD365EmailRecipients();

  useEffect(() => {
    if (accountsData) {
      let data = accountsData.results;
      if (data.length < 2) {
        data.push({
          id: "-1",
          username: "",
          password: "",
          account_type: "PowerApps",
        });
      }
      setAccounts(data);
    }
  }, [accountsData]);

  useEffect(() => {
    if (emailRecipientsData) {
      setEmailRecipients(emailRecipientsData.results);
    }
  }, [emailRecipientsData]);

  return (
    <Box>
      <Typography variant="h6" marginBottom={3}>
        Trigger Functions
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          mb: 10,
          alignItems: "center",
          gap: 5,
        }}
      >
        <FormControlLabel
          label="All"
          control={
            <Checkbox
              checked={reports.every((r) => r.is_selected)}
              indeterminate={
                reports.some((r) => r.is_selected) &&
                !reports.every((r) => r.is_selected)
              }
              onChange={(e, check) => {
                let updatedReports = reports.map((r) => ({
                  ...r,
                  is_selected: check,
                }));
                setReports(updatedReports);
              }}
            />
          }
        />
        <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
          {reports.map((report) => (
            <FormControlLabel
              key={report.name}
              label={report.name}
              control={
                <Checkbox
                  checked={report.is_selected}
                  onChange={(e, check) => {
                    setReports(
                      reports.map((r) =>
                        r.name === report.name
                          ? { ...r, is_selected: check }
                          : r
                      )
                    );
                  }}
                />
              }
            />
          ))}
        </Box>
        <RunSelectedButton onClick={handleRunReports} />
      </Box>
      {(accountsIsLoading || emailRecipientsIsLoading) && <div>Loading...</div>}
      <Typography variant="h6" marginBottom={3}>
        Service Accounts
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          mb: 10,
        }}
      >
        {accounts.map((account) => (
          <D365AccountCard
            key={account.username}
            data={account}
            mutate={accountsMutate}
          />
        ))}
      </Box>
      <Typography variant="h6" marginBottom={3}>
        Email Recipients
      </Typography>
      <D365EmailRecipientTable
        recipients={emailRecipients}
        handleAdd={() => handleEditRecipient(initialState)}
        handleEdit={(recipient) => handleEditRecipient(recipient)}
        handleDelete={(recipient) => handleEditRecipient(recipient, true)}
        mutate={recipientMutate}
      />
      <D365EmailRecipientForm
        initialValues={selectRecipient}
        open={open}
        handleClose={handleClose}
        mutate={recipientMutate}
      />
      <D365EmailRecipientDialog
        open={openConfirmationDialog}
        handleClose={handleCloseConfirmationDialog}
        title="Confirm Delete Recipient?"
        body="This action is irreversible."
        buttonText="Delete"
        buttonOnClick={() => handleDelete(selectRecipient.id)}
        isButtonLoading={isDeleting}
      />
    </Box>
  );
};

export default D365Page;
