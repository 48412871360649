import { Box } from "@mui/material";
import SetNewPasswordForm from "../components/SetNewPassword/SetNewPasswordForm";
import PageTitle from "../components/PageTitle";

const SetNewPasswordPage = () => {
  return (
    <>
      <PageTitle title="Set New Password"/>
      <Box
        sx={{
          flex: 1,
          display: { xs: "none", md: "flex" },
          background: "linear-gradient(to right, #5570F1, #375AA2)",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={"/images/login/landing-image.png"}
          alt={"login-landing-pic"}
          loading="lazy"
          width={300}
          height={300}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          width: "100%",
          display: "flex",
          bgcolor: "background.default",
          alignItems: "center",
        }}
      >
        <SetNewPasswordForm />
      </Box>
    </>
  );
};

export default SetNewPasswordPage;
