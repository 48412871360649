import { PaletteMode } from "@mui/material";

export const setThemeMode = (mode: string) => {
  localStorage.setItem("THEME_MODE", mode);
};

export const getThemeMode = (): PaletteMode => {
  let result: PaletteMode = "light";
  result = window.localStorage.getItem("THEME_MODE") as PaletteMode;
  return result;
};

export const setTokensToLocalStorage = (
  accessToken?: string,
  refreshToken?: string
) => {
  accessToken &&
    accessToken !== "" &&
    localStorage.setItem("ACCESS", accessToken);
  refreshToken &&
    refreshToken !== "" &&
    localStorage.setItem("REFRESH", refreshToken);
};

export const getAccessTokenFromLocalStorage = () => {
  const result =
    typeof window !== "undefined"
      ? window.localStorage.getItem("ACCESS")
      : false;

  return result;
};
export const getRefreshTokenFromLocalStorage = () => {
  const result =
    typeof window !== "undefined"
      ? window.localStorage.getItem("REFRESH")
      : false;

  return result;
};

export const clearLocalStorage = () => {
  localStorage.removeItem("ACCESS");
  localStorage.removeItem("REFRESH");
};

export function setLoginRedirectPathname(pathname: string) {
  localStorage.setItem("LOGIN_REDIRECT_PATHNAME", pathname);
}
