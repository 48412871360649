import { Box } from "@mui/material";
import SignupForm from "../components/Signup/SignupForm";
import PageTitle from "../components/PageTitle";

const SignupPage = () => {
  return (
    <>
      <PageTitle title="Sign Up"/>
      <Box
        sx={{
          flex: 1,
          display: { xs: "none", md: "flex" },
          background: "linear-gradient(to right, #5570F1, #375AA2)",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={"/images/signup/landing-image.png"}
          alt={"signup-landing-pic"}
          loading="lazy"
          width={300}
          height={300}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          width: "100%",
          display: "flex",
          bgcolor: "background.default",
          alignItems: "center",
        }}
      >
        <SignupForm />
      </Box>
    </>
  );
};

export default SignupPage;
