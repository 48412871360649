import { GridColDef, GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import { PaginationModel } from "../../interfaces/pagination";
import { InstalledProduct } from "../../interfaces/installedProduct";
import Table, { Text } from "../Table";
import { formatDateTime } from "../../utils/date.format.utils";
import { Button, Typography, TextField, IconButton  } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import { proceedInstalledProduct, cancelInstalledProduct, updateInstalledProduct } from "../../services/installedProducts";

const InstalledProductsTable = ({
  installedProducts,
  rowCount,
  paginationModel,
  setPaginationModel,
  handleFilterModelChange,
  handleSortModelChange,
  isLoading,
  refreshInstalledProducts
}: {
  installedProducts: InstalledProduct[];
  rowCount: number;
  paginationModel: PaginationModel;
  setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModel>>;
  handleFilterModelChange: (filterModel: GridFilterModel) => void;
  handleSortModelChange: (sortModel: GridSortModel) => void;
  isLoading: boolean;
  refreshInstalledProducts: () => void;
}) => {
  const TRUCATE_LENGTH = 100;
  const [showAll, setShowAll] = useState<Map<string, boolean>>(new Map());
  const [originalPrice, setOriginalPrice] = useState<Map<string, string>>(new Map());
  const [originalCost, setOriginalCost] = useState<Map<string, string>>(new Map());
  const [originalQuantity, setOriginalQuantity] = useState<Map<string, string>>(new Map());
  const [editablePrice, setEditablePrice] = useState<Map<string, string>>(new Map());
  const [editableCost, setEditableCost] = useState<Map<string, string>>(new Map());
  const [editableQuantity, setEditableQuantity] = useState<Map<string, string>>(new Map());
  const [editMode, setEditMode] = useState<Map<string, boolean>>(new Map());

  useEffect(() => {
    // Initialize showAll and editablePrice state with installedProducts id as key
    const showAllMap = new Map<string, boolean>();
    const editablePriceMap = new Map<string, string>();
    const editModeMap = new Map<string, boolean>();
    const editableCostMap = new Map<string, string>();
    const editableQuantityMap = new Map<string, string>();
    const originalPriceMap = new Map<string, string>();
    const originalCostMap = new Map<string, string>();
    const originalQuantityMap = new Map<string, string>();
    installedProducts.forEach((installedProduct) => {
      if (installedProduct.id) {
        showAllMap.set(installedProduct.id.toString(), false);
        editModeMap.set(installedProduct.id.toString(), false);
        editablePriceMap.set(
          installedProduct.id.toString(),
          typeof installedProduct.end_user_price === 'number'
            ? installedProduct.end_user_price.toFixed(2)
            : installedProduct.end_user_price
            ? parseFloat(installedProduct.end_user_price).toFixed(2)
            : ""
        );
        editableCostMap.set(
          installedProduct.id.toString(),
          typeof installedProduct.costing === 'number'
            ? installedProduct.costing.toFixed(2)
            : installedProduct.costing
            ? parseFloat(installedProduct.costing).toFixed(2)
            : ""
        );
        editableQuantityMap.set(
          installedProduct.id.toString(),
          installedProduct.qty?.toString()
        );
        originalPriceMap.set(
          installedProduct.id.toString(),
          typeof installedProduct.end_user_price === 'number'
            ? installedProduct.end_user_price.toFixed(2)
            : installedProduct.end_user_price
            ? parseFloat(installedProduct.end_user_price).toFixed(2)
            : ""
        );
        originalCostMap.set(
          installedProduct.id.toString(),
          typeof installedProduct.costing === 'number'
            ? installedProduct.costing.toFixed(2)
            : installedProduct.costing
            ? parseFloat(installedProduct.costing).toFixed(2)
            : ""
        );
        originalQuantityMap.set(
          installedProduct.id.toString(),
          installedProduct.qty?.toString()
        );
      }
    });
    setShowAll(showAllMap);
    setEditablePrice(editablePriceMap);
    setEditMode(editModeMap);
    setEditableCost(editableCostMap);
    setEditableQuantity(editableQuantityMap);
    setOriginalPrice(originalPriceMap);
    setOriginalCost(originalCostMap);
    setOriginalQuantity(originalQuantityMap);
  }, [installedProducts]);

  const truncate = (str: string, index: number) => {
    return str.length > TRUCATE_LENGTH ? (
      <div>
        <Text
          text={
            showAll.get(index.toString())
              ? str
              : str.substring(0, TRUCATE_LENGTH) + "..."
          }
        />
        <Button
          onClick={() => {
            setShowAll(
              new Map(
                showAll.set(index.toString(), !showAll.get(index.toString()))
              )
            );
          }}
          size="small"
        >
          {showAll.get(index.toString()) ? "Show less" : "Show more"}
        </Button>
      </div>
    ) : (
      <Text text={str || "-"} />
    );
  };

  const buttonStyle = {
    minWidth: '110px',
    maxWidth: '110px',
  };

  const cellContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  };

  const updateInstalledProductStatus = (id: number) => {
    proceedInstalledProduct(id);
    // wait for 1 second to refresh the data
    setTimeout(() => {
      refreshInstalledProducts();
    }, 1000);
  }

  const cancelInstalledProductStatus = (id: number) => {
    cancelInstalledProduct(id);
    setTimeout(() => {
      refreshInstalledProducts();
    }, 1000);
  }

  const handlePriceChange = (id: string, value: string) => {
    setEditablePrice(new Map(editablePrice.set(id, value)));
  };

  const handleCostChange = (id: string, value: string) => {
    setEditableCost(new Map(editableCost.set(id, value)));
  }

  const handleQuantityChange = (id: string, value: string) => {
    setEditableQuantity(new Map(editableQuantity.set(id, value)));
  }

  const handleEditClick = (id: string) => {
    setEditMode(new Map(editMode.set(id, true)));
  };

  const handleCancelEditClick = (id: string) => {
    setEditMode(new Map(editMode.set(id, false)));
    setEditablePrice(new Map(editablePrice.set(id, originalPrice.get(id) || "")));
    setEditableCost(new Map(editableCost.set(id, originalCost.get(id) || "")));
    setEditableQuantity(new Map(editableQuantity.set(id, originalQuantity.get(id) || "")));
  }

  const handleSaveEditClick = (id: string) => {
    setEditMode(new Map(editMode.set(id, false)));
    console.log(editablePrice.get(id));
    console.log(editableCost.get(id));
    console.log(editableQuantity.get(id));

    var new_data : Partial<InstalledProduct> = {
      end_user_price: editablePrice.get(id) ? parseFloat(editablePrice.get(id)!) : 0,
      costing: editableCost.get(id) ? parseFloat(editableCost.get(id)!) : 0,
      qty: editableQuantity.get(id) ? parseInt(editableQuantity.get(id)!) : 0
    }
    
    var ori_data : Partial<InstalledProduct> = {
      end_user_price: originalPrice.get(id) ? parseFloat(originalPrice.get(id)!) : 0,
      costing: originalCost.get(id) ? parseFloat(originalCost.get(id)!) : 0,
      qty: originalQuantity.get(id) ? parseInt(originalQuantity.get(id)!) : 0
    }

    if (JSON.stringify(new_data) === JSON.stringify(ori_data)) {
      return;
    }

    var id_number: number = parseInt(id);
  
    updateInstalledProduct(id_number, new_data);
    setTimeout(() => {
      refreshInstalledProducts();
    }, 1000);
  }

  const columns: GridColDef<InstalledProduct>[] = [
    {
      field: "index",
      headerName: "No.",
      sortable: false,
      disableColumnMenu: true,
      width: 45,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <Text text={`${params.value}.`} />,
    },
    {
      field: "installed_product_id",
      headerName: "ID",
      minWidth: 50,
      flex: 1,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "product_name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "company_name",
      headerName: "Company",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "quote_number",
      headerName: "Quote Number",
      sortable: false,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "quantity",
      headerName: "Qty",
      sortable: false,
      minWidth: 50,
      flex: 1,
      renderCell: (params) => {
        return (
          <TextField
            size="small"
            type="number"
            disabled={!editMode.get(params.row.id.toString())}
            value={editableQuantity.get(params.row.id.toString()) || ""}
            onChange={(e) => handleQuantityChange(params.row.id.toString(), e.target.value)}
          />
        );
      },
    },
    {
      field: "costing",
      headerName: "Cost",
      sortable: false,
      minWidth: 110,
      flex: 1,
      renderCell: (params) => {
        return (
          <TextField
            size="small"
            type="number"
            disabled={!editMode.get(params.row.id.toString())}
            value={editableCost.get(params.row.id.toString()) || ""}
            onChange={(e) => handleCostChange(params.row.id.toString(), e.target.value)}
          />
        );
      },
    },
    {
      field: "end_user_price",
      headerName: "Price",
      sortable: false,
      minWidth: 110,
      flex: 1,
      renderCell: (params) => {
        return (
          <TextField
            size="small"
            type="number"
            disabled={!editMode.get(params.row.id.toString())}
            value={editablePrice.get(params.row.id.toString()) || ""}
            onChange={(e) => handlePriceChange(params.row.id.toString(), e.target.value)}
          />
        );
      },
    },
    {
      field: "can_edit",
      headerName: "",
      sortable: false,
      minWidth: 50,
      flex: 1,
      renderCell: (params) => {
        const isEdit = editMode.get(params.row.id.toString()) || false;
        if (params.value !== true){
          return (
            <div style={cellContainerStyle}>
              <IconButton disabled>
                <EditOffIcon />
              </IconButton>
            </div>
          );
        }else{
          return(
            <div style={cellContainerStyle}>
              {isEdit ? (
                <>
                  <IconButton onClick={() => handleSaveEditClick(params.row.id.toString())}>
                    <CheckIcon />
                  </IconButton>
                  <IconButton onClick={() => handleCancelEditClick(params.row.id.toString())}>
                    <CloseIcon />
                  </IconButton>
                </>
              ) : (
                <IconButton onClick={() => handleEditClick(params.row.id.toString())}>
                  <EditIcon />
                </IconButton>
              )}
            </div>
          );
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        if (params.value) {
          return <Button variant="contained" size="small" style={buttonStyle} onClick={() => updateInstalledProductStatus(params.row.id)}>{params.value}</Button>;
        } else {
          return <Button variant="contained" disabled style={buttonStyle} size="small">N/A</Button>;
        }
      },
    },
    {
      field: "is_cancelled",
      headerName: "",
      sortable: false,
      minWidth: 60,
      flex: 1,
      renderCell: (params) => {
        if (params.value === true) {
          return <Button variant="contained" size="small" color='warning' disabled >Cancel</Button>; 
        } else {
          return <Button variant="contained" size="small" style={{ border: '1px solid #ed6c02'}} color='warning' onClick={() => cancelInstalledProductStatus(params.row.id)}>Cancel</Button>
        }
      },
    },
    // {
    //   field: "job",
    //   headerName: "Job",
    //   minWidth: 150,
    //   flex: 1,
    //   renderCell: (params) => <Text text={params.value || "-"} />,
    // },
  ];

  return (
    <>
      <Table
        rows={installedProducts.map((installedProduct, index) => ({
          ...installedProduct,
          index: paginationModel.page * paginationModel.pageSize + (index + 1),
        }))}
        columns={columns}
        loading={isLoading}
        sx={{
          display: "grid",
          ".css-axafay-MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
        }}
        autoHeight={false}
        rowCount={rowCount}
        pageSizeOptions={[25, 50, 100]}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        filterMode="server"
        onFilterModelChange={handleFilterModelChange}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
      />
    </>
  );
};

export default InstalledProductsTable;
