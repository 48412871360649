import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";

type PasswordGuideIconButtonProps = {
  iconSize?: string | number;
  iconColor?: string;
};

const PasswordGuideIconButton = ({
  iconSize,
  iconColor,
}: PasswordGuideIconButtonProps) => {
  return (
    <Tooltip
      title={
        <Box>
          <Typography
            variant="body2"
            sx={{ color: "text.primary", fontWeight: 500, mb: 1 }}
          >
            Password must contain:
          </Typography>

          <List dense={true} disablePadding>
            {[
              "At least 1 Number",
              "At least 1 Special character",
              "At least 1 Uppercase letter",
              "At least 1 Lowercase letter",
              "At least 8 Characters long",
            ].map((item, index) => (
              <ListItem
                key={index}
                sx={{ color: "text.primary" }}
                disablePadding
                disableGutters
              >
                <CircleRoundedIcon
                  sx={{ fontSize: 8, color: "secondary.main" }}
                />

                <ListItemText
                  primary={
                    <Typography
                      variant="caption"
                      sx={{
                        color: "text.primary",

                        textAlign: "left",
                        ml: 1,
                      }}
                    >
                      {item}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      }
      componentsProps={{
        tooltip: {
          sx: {
            color: "text.primary ",
            backgroundColor: "background.default",
            p: 2,
            borderRadius: 1,
            boxShadow: 24,
            border: `0.0625rem solid #fff`,
            maxWidth: "none",
            minWidth: 200,
          },
        },
        arrow: {
          sx: {
            color: "background.paper",
          },
        },
      }}
    >
      <IconButton size="small">
        <InfoRoundedIcon
          sx={{ fontSize: iconSize ?? "small", color: iconColor ?? "inherit" }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default PasswordGuideIconButton;
