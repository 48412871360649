import { ThemeProvider } from "@mui/material";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Root from "./layout/Root";
import { ColorModeContext, useMode } from "./theme/theme";
import AlertProvider from "./contexts/alert.context";
import AuthProvider from "./contexts/auth.context";

const router = createBrowserRouter([{ path: "*", Component: Root }]);

const Providers = () => {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <AlertProvider>
          <RouterProvider router={router} />
        </AlertProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Providers;
