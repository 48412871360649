import { Box, Button, Modal, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import useAlert from "../../hooks/useAlert";
import { User } from "../../interfaces/user";
import { alertSuccess } from "../../utils/alert";
import { deleteUser } from "../../services/users";

type DeleteUserDialogProps = {
  user: User;
  open: boolean;
  handleClose: () => void;
  mutate: () => void;
};

const DeleteUserDialog = React.forwardRef<
  HTMLDivElement,
  DeleteUserDialogProps
>(function DeleteUserDialog(props, ref) {
  const { user, open, handleClose, mutate } = props;

  const { setAlertInfo } = useAlert();

  const [isLoading, setisLoading] = useState(false);

  const handleFormSubmit = async (values: any) => {
    setisLoading(true);
    await deleteUser({
      user: values,
      onSuccess: () => {
        mutate();
        handleClose();
      },
      setAlertInfo,
    });
    setisLoading(false);
  };

  return (
    <Modal ref={ref} open={open}>
      <div>
        <Formik
          initialValues={{
            id: user.id,
            name: user.name,
          }}
          onSubmit={handleFormSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({ handleSubmit }) => (
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  maxWidth: "80%",
                  width: 350,
                  backgroundColor: "background.paper",
                  borderRadius: 2,
                  paddingX: 4,
                  paddingY: 4,
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Box sx={{ marginBottom: 2, display: "flex" }}>
                  <Typography variant="h6" sx={{ color: "text.primary" }}>
                    Delete User
                  </Typography>
                </Box>

                <Box
                  sx={{
                    mt: 4,
                    mb: 5,
                    display: "flex",
                    flexDirection: "column",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.primary",
                      textAlign: "left",
                      fontWeight: 500,
                    }}
                  >
                    Are you absolutely certain you wish to proceed with deleting <b>{user.name}</b> from our records?
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color: "text.secondary",
                      textAlign: "left",
                      fontWeight: 500,
                    }}
                  >
                    Please ensure that this deletion aligns with company
                    policies and guidelines before continuing.
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", marginTop: 2 }}>
                  <Button
                    fullWidth
                    size="small"
                    disabled={isLoading}
                    variant="outlined"
                    disableElevation
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Box width={32} />
                  <Button
                    fullWidth
                    size="small"
                    disabled={isLoading}
                    variant="contained"
                    color="error"
                    disableElevation
                    type="submit"
                    sx={{ borderColor: "error.main" }}
                  >
                    Confirm Delete
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
});

export default DeleteUserDialog;
