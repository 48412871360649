import { Box, Typography } from "@mui/material";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import { useCallback, useEffect, useRef, useState } from "react";
import LoadingIndicator from "../components/LoadingIndicator";
import CustomersTable from "../components/Customers/CustomersTable";
import { PaginationModel, QueryOptions } from "../interfaces/pagination";
import { Customer } from "../interfaces/customer";
import { useAllCustomers } from "../services/customers";
import PageTitle from "../components/PageTitle";

const CustomersPage = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  // const [isLoading, setIsLoading] = useState<boolean>(true);

  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 25,
  });

  const [rowCount, setRowCount] = useState<number>(customers.length);

  const [queryOptions, setQueryOptions] = useState<QueryOptions>({});

  const timeoutRef = useRef<number | null>(null);

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = window.setTimeout(() => {
        setQueryOptions((prev) => {
          if (
            filterModel.quickFilterValues &&
            filterModel.quickFilterValues.length > 0
          ) {
            setPaginationModel((prev) => ({
              ...prev,
              page: 0,
            }));

            const search = filterModel.quickFilterValues.join(" ");
            return { ...prev, search };
          } else {
            const { search, ...others } = prev;
            return others;
          }
        });
      }, 300);
    },
    []
  );

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setQueryOptions((prev) => {
      if (sortModel.length > 0) {
        setPaginationModel((prev) => ({
          ...prev,
          page: 0,
        }));

        const ordering = `${sortModel[0].sort === "desc" ? "-" : ""}${
          sortModel[0].field
        }`;
        return { ...prev, ordering };
      } else {
        const { ordering, ...others } = prev;
        return others;
      }
    });
  }, []);

  const {
    data: customersData,
    mutate,
    isLoading,
  } = useAllCustomers({
    ...paginationModel,
    ...queryOptions,
  });

  useEffect(() => {
    setCustomers(customersData?.results || []);

    setRowCount((prevRowCount) => (customersData ? customersData.count : prevRowCount));
  }, [customersData]);

  return (
    <>
      <PageTitle title="Customers"/>
      <Box sx={{}}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <CustomersTable
            customers={customers}
            rowCount={rowCount}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            handleFilterModelChange={handleFilterModelChange}
            handleSortModelChange={handleSortModelChange}
            isLoading={isLoading}
          />
        )}
      </Box>
    </>
  );
};

export default CustomersPage;
