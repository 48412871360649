import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import { CSSObject, Theme, styled } from "@mui/material/styles";
import * as React from "react";
import { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { APP_NAME } from "../../constants/app.consts";
import { SIDEBAR_LINKS } from "../../constants/navigation";
import useModal from "../../hooks/useModal";
import ThemeSwitcher from "../../theme/ThemeSwitcher";
import DrawerListItem from "./DrawerListItem";
import MobileDrawer from "./MobileDrawer";
import UserAvatarIconButton from "./UserAvatarIconButton";
import { getSidebarMode, setSidebarMode } from "./sidebarmode.utils";
import AuthenticationWrapper from "../../components/AuthenticationWrapper/AuthenticationWrapper";
import { getUser } from "../../services/auth";
import { AuthState, User, initialState } from "../../interfaces/user";
import useAuth from "../../hooks/useAuth";
import Button from "@mui/material/Button";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { clearLocalStorage } from "../../utils/auth";
import { useNavigate } from "react-router-dom";

const drawerMaxWidth = 240;
const drawerMinWidth = 64;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerMaxWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerMaxWidth,
    width: `calc(100% - ${drawerMaxWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerMaxWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const TruncatedTypography = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.primary,
  fontWeight: 600,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: 240,
}));

export default function DashboardLayout() {
  const { pathname } = useLocation();
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const { handleClose } = useModal();

  const {
    open: openMobileDrawer,
    handleOpen: handleOpenMobileDrawer,
    handleClose: handleCloseMobileDrawer,
  } = useModal();

  const sidebarMode = getSidebarMode();

  const [open, setOpen] = useState(sidebarMode === "Collapsed" ? false : true);
  const headerWidth = open ? drawerMaxWidth : drawerMinWidth;

  const handleDrawerToggle = () => {
    setOpen((value) => {
      if (value) {
        setSidebarMode("Collapsed");
      } else {
        setSidebarMode("Expanded");
      }
      return !value;
    });
  };

  let maxAppNameLength = 0;
  const windowWidth = window.innerWidth;
  if (windowWidth < 600) {
    maxAppNameLength = 10;
  } else if (windowWidth < 1200) {
    maxAppNameLength = 12;
  } else {
    maxAppNameLength = 15;
  }
  const truncatedAppName = APP_NAME.length > maxAppNameLength
    ? `${APP_NAME.slice(0, maxAppNameLength)}...`
    : APP_NAME;

  const handleLogout = () => {
    clearLocalStorage();
    setUser(undefined);
    navigate("/", { replace: true });
    handleClose();
  };

  return (
    <>
      <AuthenticationWrapper>
        <MobileDrawer
          open={openMobileDrawer}
          handleClose={handleCloseMobileDrawer}
        />
        {/* <AppBar
        elevation={0}
        position="fixed"
        open={open}
        sx={{
          width: { xs: "100%", md: `calc(100% - ${headerWidth}px)` },
          ml: { xs: 0, md: `${headerWidth}px` },
          backgroundColor: "background.paper",
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleOpenMobileDrawer}
            edge="start"
            sx={{
              display: { xs: "block", md: "none" },
              marginRight: 5,
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            sx={{ flexGrow: 1, color: "text.primary", fontSize: 20 }}
          ></Typography>
        </Toolbar>
      </AppBar> */}
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            display: { xs: "none", md: "block" },
            backgroundColor: "background.paper",
          }}
        >
          <DrawerHeader>
            <Box
              sx={{
                flex: 1,
                alignItems: "center",
                gap: 1,
                pl: 1,
                ...(open ? { display: "flex" } : { display: "none" }),
              }}
            >
              <img
                src={"/logo192.png"}
                alt={"loading-logo"}
                loading="lazy"
                width={36}
                height={36}
              />
              <Typography
                sx={{ fontSize: 16, color: "text.primary", fontWeight: 600 }}
              >
                <TruncatedTypography>
                  {truncatedAppName}
                </TruncatedTypography>
              </Typography>
            </Box>

            <IconButton color="primary" onClick={handleDrawerToggle}>
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Box
            sx={{
              flex: 1,
              paddingY: 3,
              paddingX: open ? 2 : 1.5,
              justifySelf: "center",
              display: {
                xs: "none",
                md: "flex",
              },
              flexDirection: "column",
              gap: 1.5,
            }}
          >
            {SIDEBAR_LINKS.map((link, index) => (
              <DrawerListItem
                key={`sidebar-item-${index}`}
                selected={pathname === link.href}
                isDrawerMinimized={!open}
                link={link}
              />
            ))}

          </Box>
          <Typography
            sx={{
              textAlign: "center",
            }}
          >
            <ThemeSwitcher />
            <Button
              color="primary"
              onClick={handleLogout}
            >
              <ExitToAppIcon sx={{ fontSize: 20 }} />
            </Button>
          </Typography>
          <Typography
            sx={{
              pb: 2,
              fontSize: 12,
              textAlign: "center",
              display: open ? "block" : "none",
            }}
          >
            Copyright © {new Date().getFullYear()} | {truncatedAppName}
          </Typography>
        </Drawer>
        <Box
          component="main"
          sx={{
            maxWidth: { xs: "100%", md: `calc(100% - ${headerWidth}px)` },
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "background.default",
            p: 3,
            overflow: "hidden",
          }}
        >
          {/* <Toolbar /> */}
          <Box
            sx={{
              backgroundColor: "background.paper",
              mb: 3,
              px: 3,
              py: 1,
              borderRadius: 1,
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" fontSize="small" href="/">
                Home
              </Link>
              <Link
                underline="hover"
                color="text.primary"
                fontSize="small"
                href={pathname}
                aria-current="page"
              >
                {convertFirstLetterUppercase(pathname.replace("/", "") ?? "")}
              </Link>
            </Breadcrumbs>
          </Box>
          <Outlet />
        </Box>
      </AuthenticationWrapper>
    </>
  );
}

function convertFirstLetterUppercase(text: string) {
  if (text === "") {
    return "";
  }
  return text.slice(0, 1).toUpperCase() + text.slice(1, text.length);
}
