import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { D365EmailRecipient } from "../../interfaces/d365";
import Table, { Text } from "../Table";
import { Button, Checkbox, Tooltip } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { updateD365EmailRecipient } from "../../services/d365";
import useAlert from "../../hooks/useAlert";

const D365EmailRecipientTable = ({
  recipients,
  handleAdd,
  handleEdit,
  handleDelete,
  mutate,
}: {
  recipients: D365EmailRecipient[];
  handleAdd: () => void;
  handleEdit: (recipient: D365EmailRecipient) => void;
  handleDelete: (recipient: D365EmailRecipient) => void;
  mutate: () => void;
}) => {
  const noRecipients = recipients.length === 0;

  const { setAlertInfo } = useAlert();

  const handleUpdateActive = async (recipientID: string, isActive: boolean) => {
    await updateD365EmailRecipient({
      recipientId: recipientID,
      body: { is_active: isActive },
      onSuccess: () => {
        mutate();
      },
      setAlertInfo,
    });
  };

  const columns: GridColDef<D365EmailRecipient>[] = [
    {
      field: "index",
      headerName: "No.",
      sortable: false,
      disableColumnMenu: true,
      width: 45,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        params.value === 0 ? (
          <Button
            fullWidth
            variant="outlined"
            sx={{
              backgroundColor: "background.default",
              borderRadius: 0,
              textTransform: "none",
              py: 1.5,
            }}
            onClick={handleAdd}
          >
            Add new recipient!
          </Button>
        ) : (
          <Text text={`${params.value}.`} />
        ),
      colSpan: (params) => (params.value === 0 ? columns.length : 1),
    },
    {
      field: "email",
      headerName: "Email",
      width: 450,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "is_active",
      headerName: "Enabled",
      width: 150,
      // renderCell: (params) => <Text text={params.value ? "Yes" : "No"} />,
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onChange={() => {
            console.log(params);
            handleUpdateActive(params.row.id, !params.value);
          }}
        />
      ),
    },
    {
      field: "Actions",
      type: "actions",
      headerName: "Actions",
      getActions: (params) => [
        <Tooltip key="edit" title="Edit">
          <GridActionsCellItem
            icon={<EditOutlinedIcon />}
            label="Edit"
            onClick={() => handleEdit(params.row)}
          />
        </Tooltip>,
        <Tooltip key="delete" title="Delete">
          <GridActionsCellItem
            icon={<DeleteOutlinedIcon color="error" />}
            label="Delete"
            onClick={() => handleDelete(params.row)}
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <Table
      columns={columns}
      rows={
        noRecipients
          ? [{ index: 0, id: "1" }]
          : recipients
              .map((recipient, index) => ({
                ...recipient,
                index: index + 1,
              }))
              // add empty object to the end of the array
              .concat({ index: 0, id: "0", email: "", is_active: false })
      }
    />
  );
};

export default D365EmailRecipientTable;
