import AddAPhotoRoundedIcon from "@mui/icons-material/AddAPhotoRounded";
import EditOffRoundedIcon from "@mui/icons-material/EditOffRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Avatar, Badge, Box, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import ProfileInformationListItem from "./ProfileInformationListItem";
import UserProfileEditForm from "./UserProfileEditForm";
import { getUser } from "../../services/auth";
import { AuthState, User, initialState } from "../../interfaces/user";
import useAuth from "../../hooks/useAuth";

const UserProfile = () => {
  const [enableEdit, setEnableEdit] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "background.paper",
        paddingY: { lg: 2, xs: 2 },
        paddingX: { lg: 4, xs: 4 },
        borderRadius: 1,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
        }}
      >
        <IconButton
          color="primary"
          onClick={() => setEnableEdit((flag) => !flag)}
        >
          {/* {enableEdit ? <EditRoundedIcon /> : <EditOffRoundedIcon />} */}
        </IconButton>
      </Box>

      <Box sx={{ paddingY: 2, marginBottom: 2 }}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <Avatar
              sx={{
                width: 24,
                height: 24,
                bgcolor: "primary.main",
                cursor: "pointer",
              }}
            >
              <AddAPhotoRoundedIcon sx={{ fontSize: 14, color: "white" }} />
            </Avatar>
          }
        >
          <Avatar
            alt="User Profile Picture"
            src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cG9ydHJhaXR8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
            sx={{ width: 72, height: 72 }}
          />
        </Badge>
      </Box>
      {enableEdit ? <UserProfileEditForm /> : <UserProfileViewSection />}
    </Box>
  );
};

const UserProfileViewSection = () => {
  const { user } = useAuth();
  return (
    <>
      <ProfileInformationListItem label={"Full Name"} text={user?.name ?? ""} />
      <ProfileInformationListItem label={"Email"} text={user?.email ?? ""} />
    </>
  );
};

export default UserProfile;
