import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  GridActionsCellItem,
  GridColDef,
  GridFilterModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { useState } from "react";
import useModal from "../../hooks/useModal";
import { PaginationModel } from "../../interfaces/pagination";
import { Customer } from "../../interfaces/customer";
import Table, { Text } from "../Table";
import CreateCustomerDialog from "./CreateCustomerDialog";
import DeleteCustomerDialog from "./DeleteCustomerDialog";
import EditCustomerDialog from "./EditCustomerDialog";
import ViewIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ViewCustomerDialog from "./ViewCustomerDialog";

const CustomersTable = ({
  customers,
  rowCount,
  paginationModel,
  setPaginationModel,
  handleFilterModelChange,
  handleSortModelChange,
  isLoading,
}: {
  customers: Customer[];
  rowCount: number;
  paginationModel: PaginationModel;
  setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModel>>;
  handleFilterModelChange: (filterModel: GridFilterModel) => void;
  handleSortModelChange: (sortModel: GridSortModel) => void;
  isLoading: boolean;
}) => {
  const {
    open: viewOpen,
    handleOpen: handleViewOpen,
    handleClose: handleViewClose,
  } = useModal();
  const { open, handleOpen, handleClose } = useModal();
  const {
    open: editOpen,
    handleOpen: handleEditOpen,
    handleClose: handleEditClose,
  } = useModal();
  const {
    open: deleteOpen,
    handleOpen: handleDeleteOpen,
    handleClose: handleDeleteClose,
  } = useModal();

  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();

  const columns: GridColDef<Customer>[] = [
    {
      field: "index",
      headerName: "No.",
      sortable: false,
      disableColumnMenu: true,
      width: 45,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <Text text={`${params.value}.`} />,
    },
    {
      field: "customer",
      headerName: "Organization ID",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "customerName",
      headerName: "Organization",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "actions",
      type: "actions",
      width: 36,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            key={"view"}
            icon={<ViewIcon color="primary" fontSize="small" />}
            label="View"
            showInMenu
            onClick={() => {
              setSelectedCustomer(params.row);
              handleViewOpen();
            }}
          />,
          <GridActionsCellItem
            key={"edit"}
            icon={<EditIcon color="primary" fontSize="small" />}
            label="Edit"
            showInMenu
            onClick={() => {
              setSelectedCustomer(params.row);
              handleEditOpen();
            }}
          />,
          <GridActionsCellItem
            key={"delete"}
            icon={<DeleteIcon color="error" fontSize="small" />}
            label="Delete"
            showInMenu
            onClick={() => {
              setSelectedCustomer(params.row);
              handleDeleteOpen();
            }}
          />,
        ];
      },
    },
  ];

  return (
    <>
      <CreateCustomerDialog open={open} handleClose={handleClose} />
      {selectedCustomer && (
        <EditCustomerDialog
          customer={selectedCustomer}
          open={editOpen}
          handleClose={handleEditClose}
        />
      )}
      {selectedCustomer && (
        <DeleteCustomerDialog
          customer={selectedCustomer}
          open={deleteOpen}
          handleClose={handleDeleteClose}
        />
      )}
      {selectedCustomer && (
        <ViewCustomerDialog
          customer={selectedCustomer}
          open={viewOpen}
          handleClose={handleViewClose}
        />
      )}
      {/* <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
        <Button
          size="small"
          variant="contained"
          startIcon={<AddRoundedIcon />}
          onClick={handleOpen}
        >
          Add Customer
        </Button>
      </Box> */}
      <Table
        rows={customers.map((customer, index) => ({
          ...customer,
          index: paginationModel.page * paginationModel.pageSize + (index + 1),
        }))}
        columns={columns}
        loading={isLoading}
        sx={{
          display: "grid",
          ".css-axafay-MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
        }}
        autoHeight={false}
        rowCount={rowCount}
        pageSizeOptions={[25, 50, 100]}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        filterMode="server"
        onFilterModelChange={handleFilterModelChange}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
      />
    </>
  );
};

export default CustomersTable;
