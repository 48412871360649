import { Box, Typography } from "@mui/material";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import { useCallback, useEffect, useRef, useState } from "react";
import LoadingIndicator from "../components/LoadingIndicator";
import LogsTable from "../components/Logs/LogsTable";
import { PaginationModel, QueryOptions } from "../interfaces/pagination";
import { Log } from "../interfaces/log";
import { useAllLogs } from "../services/logs";
import PageTitle from "../components/PageTitle";

const LogsPage = () => {
  const [logs, setLogs] = useState<Log[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 25,
  });

  const [rowCount, setRowCount] = useState<number>(logs.length);

  const [queryOptions, setQueryOptions] = useState<QueryOptions>({});

  const timeoutRef = useRef<number | null>(null);

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = window.setTimeout(() => {
        setQueryOptions((prev) => {
          if (
            filterModel.quickFilterValues &&
            filterModel.quickFilterValues.length > 0
          ) {
            setPaginationModel((prev) => ({
              ...prev,
              page: 0,
            }));

            const search = filterModel.quickFilterValues.join(" ");
            return { ...prev, search };
          } else {
            const { search, ...others } = prev;
            return others;
          }
        });
      }, 300);
    },
    []
  );

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setQueryOptions((prev) => {
      if (sortModel.length > 0) {
        setPaginationModel((prev) => ({
          ...prev,
          page: 0,
        }));

        const ordering = `${sortModel[0].sort === "desc" ? "-" : ""}${
          sortModel[0].field
        }`;
        return { ...prev, ordering };
      } else {
        const { ordering, ...others } = prev;
        return others;
      }
    });
  }, []);

  const { data: logsData, isLoading: isLogsDataLoading } = useAllLogs({
    ...paginationModel,
    ...queryOptions,
  });

  useEffect(() => {
    if (logsData) {
      setLogs(logsData.results);
    } else {
      setLogs([]);
    }
    setIsLoading(false);
    setRowCount((prevRowCount) => (logsData ? logsData.count : prevRowCount));
  }, [logsData]);

  return (
    <>
      <PageTitle title="Logs" />
      <Box sx={{}}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <LogsTable
            logs={logs}
            rowCount={rowCount}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            handleFilterModelChange={handleFilterModelChange}
            handleSortModelChange={handleSortModelChange}
            isLoading={isLogsDataLoading}
          />
        )}
      </Box>
    </>
  );
};

export default LogsPage;
