import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ViewIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Typography } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridFilterModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { useState } from "react";
import useModal from "../../hooks/useModal";
import { PaginationModel } from "../../interfaces/pagination";
import { Job } from "../../interfaces/jobs";
import Table, { Text, ToolbarWithAddButton } from "../Table";
import CreateJobDialog from "./CreateJobDialog";
import DeleteJobDialog from "./DeleteJobDialog";
import EditJobDialog from "./EditJobDialog";
import ViewJobDialog from "./ViewJobDialog";
import { formatDateTime } from "../../utils/date.format.utils";
import AddJobButton from "./addJobButton";

const JobsTable = ({
  jobs,
  rowCount,
  paginationModel,
  setPaginationModel,
  handleFilterModelChange,
  handleSortModelChange,
  isLoading,
  mutate,
}: {
  jobs: Job[];
  rowCount: number;
  paginationModel: PaginationModel;
  setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModel>>;
  handleFilterModelChange: (filterModel: GridFilterModel) => void;
  handleSortModelChange: (sortModel: GridSortModel) => void;
  isLoading: boolean;
  mutate: () => void;
}) => {
  const { open, handleOpen, handleClose } = useModal();
  const {
    open: viewOpen,
    handleOpen: handleViewOpen,
    handleClose: handleViewClose,
  } = useModal();
  const {
    open: editOpen,
    handleOpen: handleEditOpen,
    handleClose: handleEditClose,
  } = useModal();
  const {
    open: deleteOpen,
    handleOpen: handleDeleteOpen,
    handleClose: handleDeleteClose,
  } = useModal();

  const [selectedJob, setSelectedJob] = useState<Job>();

  const columns: GridColDef<Job>[] = [
    {
      field: "index",
      headerName: "No.",
      sortable: false,
      disableColumnMenu: true,
      width: 45,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <Text text={`${params.value}.`} />,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "recurrence",
      headerName: "Recurrence",
      minWidth: 150,
      flex: 1,
      renderCell: (params: any) => <Text text={params.value || "-"} />,
    },
    {
      field: "last_triggered_at",
      headerName: "Last Triggered At",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => formatDateTime(params.value),
      renderCell: (params) => (
        <Typography>
          {params.value ? formatDateTime(params.value) : "-"}
        </Typography>
      ),
    },
    {
      field: "log_count",
      headerName: "Log Count",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "actions",
      type: "actions",
      width: 36,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            key={"view"}
            icon={<ViewIcon color="primary" fontSize="small" />}
            label="View"
            showInMenu
            onClick={() => {
              setSelectedJob(params.row);
              handleViewOpen();
            }}
          />,
          <GridActionsCellItem
            key={"edit"}
            icon={<EditIcon color="primary" fontSize="small" />}
            label="Edit"
            showInMenu
            onClick={() => {
              setSelectedJob(params.row);
              handleEditOpen();
            }}
          />,
          <GridActionsCellItem
            key={"delete"}
            icon={<DeleteIcon color="error" fontSize="small" />}
            label="Delete"
            showInMenu
            onClick={() => {
              setSelectedJob(params.row);
              handleDeleteOpen();
            }}
          />,
        ];
      },
    },
  ];

  return (
    <>
      <CreateJobDialog open={open} handleClose={handleClose} mutate={mutate} />
      {selectedJob && (
        <ViewJobDialog
          job={selectedJob}
          open={viewOpen}
          handleClose={handleViewClose}
        />
      )}
      {selectedJob && (
        <EditJobDialog
          job={selectedJob}
          open={editOpen}
          handleClose={handleEditClose}
          mutate={mutate}
        />
      )}
      {selectedJob && (
        <DeleteJobDialog
          job={selectedJob}
          open={deleteOpen}
          handleClose={handleDeleteClose}
        />
      )}
      <Table
        rows={jobs.map((job, index) => ({
          ...job,
          index: paginationModel.page * paginationModel.pageSize + (index + 1),
        }))}
        columns={columns}
        loading={isLoading}
        sx={{
          display: "grid",
          ".css-axafay-MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
        }}
        autoHeight={false}
        rowCount={rowCount}
        pageSizeOptions={[25, 50, 100]}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        filterMode="server"
        onFilterModelChange={handleFilterModelChange}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        slots={{ toolbar: ToolbarWithAddButton }}
        slotProps={{
          toolbar: {
            children: <AddJobButton onClick={handleOpen} />,
          },
        }}
      />
    </>
  );
};

export default JobsTable;
