type SidebarMode = "Expanded" | "Collapsed";

export function setSidebarMode(mode: SidebarMode) {
  localStorage.setItem("SIDEBAR_MODE", mode);
}

export function getSidebarMode(): SidebarMode {
  const result =
    typeof window !== "undefined"
      ? window.localStorage.getItem("SIDEBAR_MODE")
      : "Expanded";

  return result ? (result as SidebarMode) : "Expanded";
}
