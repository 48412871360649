import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import useAlert from "../../hooks/useAlert";
import { alertSuccess } from "../../utils/alert";
import PasswordGuideIconButton from "../PasswordGuideIconButton";

const initialValues = {
  password: null,
  confirm_password: null,
};

const SetNewPasswordSchema = Yup.object().shape({
  password: Yup.string().required("Please enter your password"),
  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref("password"), undefined],
      "Password and confirm password must be the same"
    )
    .required("Please retype your password"),
});

const SetNewPasswordForm = () => {
  const navigate = useNavigate();
  const { setAlertInfo } = useAlert();

  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values: any) => {
    setIsLoading(true);

    setIsLoading(false);

    alertSuccess("Welcome back!", setAlertInfo);

    navigate("/users", { replace: true });
  };

  return (
    <Box
      sx={{
        width: 350,
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        margin: "auto",
        alignItems: "center",
      }}
    >
      <img
        src={"/logo192.png"}
        alt={"loading-logo"}
        loading="lazy"
        width={48}
        height={48}
      />

      <Typography
        sx={{ textAlign: "center", fontSize: 20, color: "text.primary" }}
      >
        Set new password
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          mt: 4,
          mb: 2,
          justifyContent: "start",
        }}
      >
        <Typography
          sx={{
            textAlign: "left",
            fontSize: 14,
            color: "text.primary",
          }}
        >
          Guidelines for creating password.
        </Typography>
        <PasswordGuideIconButton iconSize="small" iconColor="primary.main" />
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={SetNewPasswordSchema}
        onSubmit={handleFormSubmit}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Field id="password" name="password">
              {(props: any) => (
                <TextField
                  size="small"
                  fullWidth
                  id="password"
                  name="password"
                  label="New Password *"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password ?? ""}
                  placeholder="********"
                  helperText={
                    errors?.password &&
                    touched?.password &&
                    String(errors?.password)
                  }
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    marginBottom: 3,
                  }}
                  type="password"
                  error={Boolean(errors?.password && touched?.password)}
                />
              )}
            </Field>
            <Field id="confirm_password" name="confirm_password">
              {(props: any) => (
                <TextField
                  size="small"
                  fullWidth
                  id="confirm_password"
                  name="confirm_password"
                  label="Confirm Password *"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirm_password ?? ""}
                  placeholder="********"
                  helperText={
                    errors?.confirm_password &&
                    touched?.confirm_password &&
                    String(errors?.confirm_password)
                  }
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    marginBottom: 3,
                  }}
                  type="password"
                  error={Boolean(
                    errors?.confirm_password && touched?.confirm_password
                  )}
                />
              )}
            </Field>

            <Button
              variant="contained"
              fullWidth
              disabled={isLoading}
              sx={{
                backgroundColor: "primary.main",
                ":hover": { backgroundColor: "primary.main" },
                marginTop: 2,
                marginBottom: 3,
                paddingY: 1,
              }}
              type="submit"
            >
              Reset Password
            </Button>

            <Button
              variant="text"
              fullWidth
              disabled={isLoading}
              startIcon={<KeyboardBackspaceRoundedIcon />}
              sx={{
                marginTop: 2,
                marginBottom: 3,
                paddingY: 1,
              }}
              href="/login"
            >
              Back to Login
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SetNewPasswordForm;
