import {
  PaletteMode,
  Theme,
  ThemeOptions,
  alpha,
  createTheme,
} from "@mui/material";
import { createContext, useEffect, useMemo, useState } from "react";

export const tokens = (mode: PaletteMode) => ({
  ...(mode === "dark"
    ? {
        primary: "#5570F1",
        secondary: "#FFCC91",
        background: {
          default: "#1F1F1F",
          paper: "#282828",
          dark: "#121212",
        },
      }
    : {
        primary: "#5570F1",
        secondary: "#FFCC91",
        background: {
          default: "#FFFFFF",
          paper: "#F7F9FB",
          dark: "#FAFAFA",
        },
      }),
});

export const themeSettings = (mode: PaletteMode) => {
  const colors = tokens(mode);

  const themeOptions: ThemeOptions = {
    palette: {
      mode,
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
      background: {
        default: colors.background.default,
        paper: colors.background.paper,
      },
    },
    typography: {
      fontFamily: "Montserrat, sans-serif",
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: (theme) => ({
          "::-webkit-scrollbar": {
            width: 12,
            height: 12,
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: alpha(
              mode === "dark"
                ? theme.palette.common.white
                : theme.palette.common.black,
              0.05
            ),
            border: "1px solid",
            borderColor: theme.palette.divider,
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: alpha(
              mode === "dark"
                ? theme.palette.common.white
                : theme.palette.common.black,
              0.2
            ),
            borderRadius: "6px",
            border: "3px solid transparent",
            backgroundClip: "content-box",
          },
          "::-webkit-scrollbar-thumb:hover": {
            backgroundColor: alpha(
              mode === "dark"
                ? theme.palette.common.white
                : theme.palette.common.black,
              0.5
            ),
          },
          "::-webkit-scrollbar-thumb:active": {
            backgroundColor: alpha(
              mode === "dark"
                ? theme.palette.common.white
                : theme.palette.common.black,
              0.6
            ),
          },
        }),
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontWeight: "bold",
            lineHeight: 1.25,
          },
          h2: {
            fontWeight: "bold",
            lineHeight: 1.25,
          },
          h3: {
            fontWeight: "bold",
            lineHeight: 1.25,
          },
          h4: {
            fontWeight: "bold",
            lineHeight: 1.25,
          },
          h5: {
            fontWeight: "bold",
            lineHeight: 1.25,
          },
          h6: {
            fontWeight: "bold",
            lineHeight: 1.25,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: "bold",
            textTransform: "none",
            "&.Mui-disabled": {
              borderColor: "transparent",
            },
          },
          contained: {
            border: "1px solid",
            borderColor: colors.primary,
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
          },
          containedPrimary: {
            ...(mode === "dark" && {
              ":hover": {
                color: "white",
              },
            }),
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            height: "auto",
            fontWeight: "bold",
            span: {
              padding: 0,
            },
          },
          colorPrimary: {
            backgroundColor: alpha(colors.primary, 0.1),
          },
          sizeSmall: {
            letterSpacing: 0.25,
            fontSize: 10,
            padding: "2px 8px",
          },
          sizeMedium: {
            fontSize: 12,
            padding: "4px 12px",
          },
        },
      },
    },
  };

  return themeOptions;
};

export type ColorModeContextType = {
  toggleColorMode: () => void;
  toggleLightMode: () => void;
  toggleDarkMode: () => void;
};

export const ColorModeContext = createContext<ColorModeContextType>({
  toggleColorMode: () => {},
  toggleLightMode: () => {},
  toggleDarkMode: () => {},
});

export const useMode = (): [Theme, ColorModeContextType] => {
  const [mode, setMode] = useState<PaletteMode>("light");

  const colorMode: ColorModeContextType = useMemo(
    () => ({
      toggleColorMode: () => {
        // Toggle between "light" and "dark" mode
        const newMode = mode === "light" ? "dark" : "light";
        setThemeMode(newMode);
        setMode(newMode);
      },
      toggleLightMode: () => {
        setThemeMode("light");
        setMode("light");
      },
      toggleDarkMode: () => {
        setThemeMode("dark");
        setMode("dark");
      },
    }),
    [mode]
  );

  useEffect(() => {
    const currentThemeMode = getThemeMode();
    if (currentThemeMode) {
      setMode(currentThemeMode);
    }
  }, []);

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};

export function setThemeMode(mode: string) {
  localStorage.setItem("THEME_MODE", mode);
}

export function getThemeMode(): PaletteMode | undefined {
  const result =
    typeof window !== "undefined"
      ? window.localStorage.getItem("THEME_MODE")
      : undefined;

  return result === null ? undefined : (result as PaletteMode);
}
