import useSWR from "swr";
import api from "./api";
import { createFetcher } from "./fetcher";
import { Log } from "../interfaces/log";
import { alertSuccess, alertError } from "../utils/alert";
import { APIWithPagination } from "../interfaces/pagination";

export const useAllLogs = ({
  page = 0,
  pageSize = 25,
  search,
  ordering,
}: APIWithPagination & { isTenant?: boolean }) => {
  let url = `/jobs/logs/`;

  url += `?page=${page + 1}&page_size=${pageSize}`;

  if (search !== undefined) {
    url += `&search=${search}`;
  }

  if (ordering !== undefined) {
    url += `&ordering=${ordering}`;
  }

  const snapshot = useSWR<{
    count: number;
    next: string;
    previous: string;
    results: Log[];
    total_pages: number;
  }>(url, createFetcher, {
    revalidateOnMount: true,
  });

  return { ...snapshot };
};

export const addLogs = null;

export const updateLog = null;

export const deleteLog = null;
