import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import useAlert from "../../hooks/useAlert";
import { addCustomers } from "../../services/customers";

type CreateCustomerDialogProps = {
  open: boolean;
  handleClose: () => void;
};

const initialValues = {
  name: null,
  contract: null,
};

const CreateCustomerSchema = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  contract: Yup.string().required("Please select contract"),
});

const contracts = [
  "Network & PC",
  "Server/NAS/Network",
  "Firewall and NAS",
  "Server and Firewall",
];

const CreateCustomerDialog = React.forwardRef<
  HTMLDivElement,
  CreateCustomerDialogProps
>(function CreateCustomerDialog(props, ref) {
  const { open, handleClose } = props;
  const { setAlertInfo } = useAlert();
  const [isLoading, setisLoading] = useState(false);

  const handleFormSubmit = async (values: any) => {
    setisLoading(true);

    await addCustomers({
      customers: [
        {
          name: values.name,
          contract: values.contract,
        },
      ],
      onSuccess: () => {
        handleClose();
      },
      setAlertInfo,
    });

    setisLoading(false);
  };

  return (
    <Modal ref={ref} open={open}>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={CreateCustomerSchema}
          onSubmit={handleFormSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  maxWidth: "80%",
                  width: 350,
                  backgroundColor: "background.paper",
                  borderRadius: 2,
                  paddingX: 4,
                  paddingY: 4,
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Box sx={{ marginBottom: 2, display: "flex" }}>
                  <Typography variant="h6" sx={{ color: "text.primary" }}>
                    Add New Customer
                  </Typography>
                </Box>

                <Box
                  sx={{
                    mt: 4,
                    mb: 5,
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                  }}
                >
                  <Field id="name" name="name">
                    {({}) => (
                      <TextField
                        variant="standard"
                        size="small"
                        fullWidth
                        id="name"
                        name="name"
                        label="Name *"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        placeholder="e.g. John Doe"
                        InputLabelProps={{ shrink: true }}
                        helperText={
                          errors?.name && touched?.name && String(errors?.name)
                        }
                        error={Boolean(errors?.name && touched?.name)}
                      />
                    )}
                  </Field>

                  <Field id="contract" name="contract">
                    {() => (
                      <TextField
                        select
                        variant="standard"
                        size="small"
                        fullWidth
                        id="contract"
                        name="contract"
                        label="Contract *"
                        value={values.contract}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.contract && touched.contract && errors.contract
                        }
                        error={Boolean(errors.contract && touched.contract)}
                      >
                        {contracts.map((contract) => (
                          <MenuItem key={contract} value={contract}>
                            {contract}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Field>
                </Box>

                <Box sx={{ display: "flex", marginTop: 2 }}>
                  <Button
                    fullWidth
                    size="small"
                    disabled={isLoading}
                    variant="outlined"
                    disableElevation
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Box width={32} />
                  <Button
                    fullWidth
                    size="small"
                    disabled={isLoading}
                    variant="contained"
                    disableElevation
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
});

export default CreateCustomerDialog;
