import {
  Box,
  Button,
  IconButton,
  Link,
  Modal,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import useAlert from "../../hooks/useAlert";
import { alertSuccess } from "../../utils/alert";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { useNavigate } from "react-router-dom";

type CheckEmailDialogProps = {
  email: string;
  open: boolean;
  handleClose: () => void;
};

const CheckEmailDialog = React.forwardRef<
  HTMLDivElement,
  CheckEmailDialogProps
>(function CheckEmailDialog(props, ref) {
  const navigate = useNavigate();

  const { email, open, handleClose } = props;

  const { setAlertInfo } = useAlert();

  const [isLoading, setisLoading] = useState(false);

  const handleFormSubmit = async (values: any) => {
    setisLoading(true);
    alertSuccess("Resent email success.", setAlertInfo);
    setisLoading(false);
    handleClose();
    navigate("/set-new-password", { replace: true });
  };
  return (
    <Modal ref={ref} open={open}>
      <div>
        <Formik
          initialValues={{
            email: email,
          }}
          onSubmit={handleFormSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({ handleSubmit }) => (
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  maxWidth: "80%",
                  width: 350,
                  backgroundColor: "background.paper",
                  borderRadius: 2,
                  paddingX: 3,
                  paddingY: 3,
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Box
                  sx={{ width: "100%", display: "flex", justifyContent: "end" }}
                >
                  <IconButton
                    color="primary"
                    sx={{ padding: 0 }}
                    onClick={handleClose}
                  >
                    <ClearRoundedIcon />
                  </IconButton>
                </Box>
                <img
                  src={"/icons/email-sent.png"}
                  alt={"email-sent-icon"}
                  loading="lazy"
                  width={64}
                  height={64}
                />
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 20,
                    color: "text.primary",
                  }}
                >
                  Check your email
                </Typography>
                <Typography
                  sx={{
                    fontSize: 13,
                    textAlign: "center",
                    color: "text.secondary",
                    marginBottom: 6,
                  }}
                >
                  We sent a password reset link to {email}.
                </Typography>

                <Box sx={{}}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: "text.secondary",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    {"Didn't receive email? "}
                    <Link
                      component="button"
                      type="submit"
                      underline="none"
                      sx={{
                        color: isLoading ? "text.disabled" : "primary.main",
                        fontWeight: 600,
                      }}
                    >
                      {"Click to resend"}
                    </Link>
                  </Typography>
                </Box>
                <Button
                  size="small"
                  variant="text"
                  fullWidth
                  disabled={isLoading}
                  startIcon={<KeyboardBackspaceRoundedIcon />}
                  sx={{
                    marginTop: 2,
                    marginBottom: 3,
                  }}
                  href="/login"
                >
                  Back to Login
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
});

export default CheckEmailDialog;
