import { IconButton, Tooltip, useTheme as useMUITheme } from "@mui/material";
import { cloneElement, useContext } from "react";
import { MODES_OBJECT } from "../constants/mode";
import { ColorModeContext } from "./theme";

const ThemeSwitcher = () => {
  const {
    palette: { mode },
  } = useMUITheme();
  const colorMode = useContext(ColorModeContext);

  return (
    <Tooltip title={`Switch to ${mode === "dark" ? "Light" : "Dark"} Mode`}>
      <IconButton
        onClick={() => colorMode.toggleColorMode()}
        sx={{
          color: "text.secondary",
        }}
        edge="end"
      >
        {cloneElement(
          mode === "dark" ? MODES_OBJECT.light.icon : MODES_OBJECT.dark.icon,
          { fontSize: "small" }
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ThemeSwitcher;
