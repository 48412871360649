import ViewIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { DirectionsRun } from "@mui/icons-material";
import {
  GridActionsCellItem,
  GridColDef,
  GridFilterModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { useState } from "react";
import useModal from "../../hooks/useModal";
import { PaginationModel } from "../../interfaces/pagination";
import { Storage } from "../../interfaces/storage";
import Table, { Text, ToolbarWithRunSelectedButton } from "../Table";
import ViewStorageDialog from "./ViewStorageDialog";
import RunSelectedButton from "./RunSelectedButton";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import useAlert from "../../hooks/useAlert";
import { alertError, alertSuccess } from "../../utils/alert";

const StoragesTable = ({
  storages,
  rowCount,
  paginationModel,
  setPaginationModel,
  handleFilterModelChange,
  handleSortModelChange,
  isLoading,
}: {
  storages: Storage[];
  rowCount: number;
  paginationModel: PaginationModel;
  setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModel>>;
  handleFilterModelChange: (filterModel: GridFilterModel) => void;
  handleSortModelChange: (sortModel: GridSortModel) => void;
  isLoading: boolean;
}) => {
  const {
    open: viewOpen,
    handleOpen: handleViewOpen,
    handleClose: handleViewClose,
  } = useModal();
  const [selectedStorage, setSelectedStorage] = useState<Storage | undefined>();
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const { setAlertInfo } = useAlert();

  // Pass the customer name to backend to run docker
  const handleRun = async (values: any) => {
    try {
      let response;

      if (Array.isArray(values)) {
        const ids = values.map((storage) => storage.id);
        response = await axios.post(
          `${process.env.REACT_APP_API_DOMAIN}/storages/run-docker/`,
          {
            payload: ids,
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_API_DOMAIN}/storages/run-docker/`,
          {
            payload: values.id,
          }
        );
      }

      if (response.status === 200) {
        alertSuccess("Docker run was successful!", setAlertInfo);
      } else {
        alertError("Failed to run Docker.", setAlertInfo);
      }
    } catch (error) {
      alertError("Error running Docker.", setAlertInfo);
    }
  };

  // Checkbox function
  const handleCheckboxChange = (id: string) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [id]: !prevSelectedRows[id],
    }));
  };

  const handleCheckAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const newSelectedRows: Record<string, boolean> = {};

    storages.forEach((storage) => {
      newSelectedRows[storage.id!.toString()] = checked;
    });

    setSelectedRows(newSelectedRows);
  };

  // Run selected checkbox
  const handleRunSelected = () => {
    const selectedIds = Object.keys(selectedRows).filter(
      (id) => selectedRows[id]
    );

    if (selectedIds.length > 0) {
      const selectedStorages = storages.filter((storage) =>
        selectedIds.includes(storage.id!.toString())
      );
      handleRun(selectedStorages);
    }
  };

  const columns: GridColDef<Storage>[] = [
    {
      field: "checkbox",
      headerName: "",
      width: 36,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Checkbox
          indeterminate={false}
          checked={
            Object.values(selectedRows).every((value) => value === true) &&
            Object.keys(selectedRows).length > 0
          }
          onChange={handleCheckAllChange}
        />
      ),
      renderCell: (params) => (
        <Checkbox
          checked={selectedRows[params.row.id!.toString()] || false}
          onChange={() => handleCheckboxChange(params.row.id!.toString())}
        />
      ),
    },
    {
      field: "index",
      headerName: "No.",
      sortable: false,
      disableColumnMenu: true,
      width: 45,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <Text text={`${params.value}.`} />,
    },
    {
      field: "customerName",
      headerName: "Organization",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "url",
      headerName: "URL",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "username",
      headerName: "Username",
      minWidth: 50,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "actions",
      type: "actions",
      width: 36,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            key={"view"}
            icon={<ViewIcon color="primary" fontSize="small" />}
            label="View"
            showInMenu
            onClick={() => {
              setSelectedStorage(params.row);
              handleViewOpen();
            }}
          />,
          <GridActionsCellItem
            key={"run"}
            icon={<DirectionsRun color="primary" fontSize="small" />}
            label="Run"
            showInMenu
            onClick={() => handleRun(params.row)}
          />,
          <GridActionsCellItem
            key={"run"}
            icon={<AccessTimeIcon color="primary" fontSize="small" />}
            label="Logs"
            showInMenu
            onClick={() => handleRun(params.row)}
          />,
        ];
      },
    },
  ];

  return (
    <>
      {selectedStorage && (
        <ViewStorageDialog
          storage={selectedStorage}
          open={viewOpen}
          handleClose={handleViewClose}
        />
      )}
      <Table
        rows={storages.map((storage, index) => ({
          ...storage,
          index: paginationModel.page * paginationModel.pageSize + (index + 1),
        }))}
        columns={columns}
        loading={isLoading}
        sx={{
          display: "grid",
          ".css-axafay-MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
        }}
        autoHeight={false}
        rowCount={rowCount}
        pageSizeOptions={[25, 50, 100]}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        filterMode="server"
        onFilterModelChange={handleFilterModelChange}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        slots={{ toolbar: ToolbarWithRunSelectedButton }}
        slotProps={{
          toolbar: {
            selectedRowCount: Object.values(selectedRows).filter(
              (value) => value
            ).length,
            children: <RunSelectedButton onClick={handleRunSelected} />,
          },
        }}
      />
    </>
  );
};

export default StoragesTable;
