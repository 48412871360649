import { Link, Route, Routes } from "react-router-dom";
import Page from "../pages/Page";
import LoginPage from "../pages/LoginPage";
import SignupPage from "../pages/SignupPage";
import UsersPage from "../pages/UsersPage";
import RootLayout from "./RootLayout";
import DashboardLayout from "./dashboard/DashboardLayout";
import SettingPage from "../pages/SettingPage";
import ForgetPasswordPage from "../pages/ForgetPasswordPage";
import SetNewPasswordPage from "../pages/SetNewPasswordPage";
import StoragesPage from "../pages/StoragesPage";
import CustomersPage from "../pages/CustomersPage";
import JobsPage from "../pages/JobsPage";
import LogsPage from "../pages/LogsPage";
import D365Page from "../pages/D365Page";
import ContractsPage from "../pages/ContractsPage";
import InstalledProductsPage from "../pages/InstalledProductsPage";

function Root() {
  return (
    <Routes>
      <Route element={<RootLayout />}>
        <Route path="/" element={<Page />} />
        <Route path="/login/*" element={<LoginPage />} />
        <Route path="/signup/*" element={<SignupPage />} />
        <Route path="/forget-password/*" element={<ForgetPasswordPage />} />
        <Route path="/set-new-password/*" element={<SetNewPasswordPage />} />
        <Route element={<DashboardLayout />}>
          <Route
            path="/users/*"
            element={<UsersPage />}
            handle={{
              crumb: () => <Link to="/users">Users</Link>,
            }}
          />
          <Route
            path="/customers/*"
            element={<CustomersPage />}
            handle={{
              crumb: () => <Link to="/customers">Customers</Link>,
            }}
          />
          <Route
            path="/jobs/*"
            element={<JobsPage />}
            handle={{
              crumb: () => <Link to="/jobs">Jobs</Link>,
            }}
          />
          <Route
            path="/storages/*"
            element={<StoragesPage />}
            handle={{
              crumb: () => <Link to="/storages">Storages</Link>,
            }}
          />
          <Route
            path="/d365_reports/*"
            element={<D365Page />}
            handle={{
              crumb: () => <Link to="/d365_reports">D365 Reports</Link>,
            }}
          />
          <Route
            path="/logs/*"
            element={<LogsPage />}
            handle={{
              crumb: () => <Link to="/logs">Storages</Link>,
            }}
          />
          <Route
            path="/contracts/*"
            element={<ContractsPage />}
            handle={{
              crumb: () => <Link to="/contracts">Contracts</Link>,
            }}
          />
          <Route
            path="/installed_products/*"
            element={<InstalledProductsPage />}
            handle={{
              crumb: () => <Link to="/installed_products">Installed Products</Link>,
            }}
          />
          <Route
            path="/setting/*"
            element={<SettingPage />}
            handle={{
              crumb: () => <Link to="/setting">Setting</Link>,
            }}
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default Root;
