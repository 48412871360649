import React, {
  useState,
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
} from "react";
import { AuthState, User, initialState } from "../interfaces/user";
import { getUser } from "../services/auth";

export const AuthContext = createContext<AuthState>({
  user: initialState,
  setUser: () => {},
});

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(initialState);
  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUser();
      setUser(user);
    };

    fetchUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
