import React from "react";
import { useState, createContext, FC, PropsWithChildren } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
import { AlertState } from "../interfaces/alert.state";

export const AlertContext = createContext<AlertState>({
  alertInfo: {
    open: false,
    severity: "error",
    msg: "",
  },
  setAlertInfo: () => {},
});

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertProvider: FC<PropsWithChildren> = ({ children }) => {
  const [alertInfo, setAlertInfo] = useState({
    open: false,
    severity: "error" as AlertColor,
    msg: "",
  });

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertInfo((prev: any) => ({
      ...prev,
      open: false,
    }));
  };

  return (
    <AlertContext.Provider value={{ alertInfo, setAlertInfo }}>
      <>
        <Snackbar
          sx={{ color: "white" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={alertInfo.open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={alertInfo.severity}
            sx={{ width: "100%", color: "white" }}
          >
            {alertInfo.msg}
          </Alert>
        </Snackbar>

        {children}
      </>
    </AlertContext.Provider>
  );
};

export default AlertProvider;
