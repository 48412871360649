import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import {
  clearLocalStorage,
  getAccessTokenFromLocalStorage,
} from "../utils/auth";
import { getNewAccessToken } from "./auth";
import { baseAPIUrl } from "../utils/url";

const api: AxiosInstance = axios.create({
  baseURL: baseAPIUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

api.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const accessToken = getAccessTokenFromLocalStorage();

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error: AxiosError): Promise<AxiosError> => Promise.reject(error)
);

api.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => response,
  async (error: AxiosError): Promise<AxiosError> => {
    const status = error.response?.status;

    if (status === 401) {
      const prevRequest = error.config;

      // @ts-ignore
      if (prevRequest && !prevRequest?.sent) {
        // @ts-ignore
        prevRequest.sent = true;

        const newAccessToken = await getNewAccessToken();

        if (newAccessToken) {
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axios(prevRequest);
        } else {
          if (typeof window !== "undefined" && window.self === window.top) {
            clearLocalStorage();
          }
        }
      }
    }

    return Promise.reject(error);
  }
);

export default api;
