import { PaletteMode } from "@mui/material";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LaptopOutlinedIcon from "@mui/icons-material/LaptopOutlined";
import { ModeType } from "../types/mode";

export const MODES_OBJECT = {
  light: {
    icon: <WbSunnyOutlinedIcon />,
    title: "Light Mode",
  },
  dark: {
    icon: <DarkModeOutlinedIcon />,
    title: "Dark Mode",
  },
  system: {
    icon: <LaptopOutlinedIcon />,
    title: "System",
  },
};

export const MODES: ModeType[] = Object.entries(MODES_OBJECT).map(
  ([type, object]) => ({
    type: type as PaletteMode,
    ...object,
  })
);
