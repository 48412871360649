import { useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { D365EmailRecipient } from "../../interfaces/d365";
import useAlert from "../../hooks/useAlert";
import {
  createD365EmailRecipient,
  updateD365EmailRecipient,
} from "../../services/d365";
import { CustomModal } from "../CustomModal";

const RecipientSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
});

const D365EmailRecipientForm = ({
  initialValues,
  open,
  handleClose,
  mutate,
}: {
  initialValues: D365EmailRecipient;
  open: boolean;
  handleClose: () => void;
  mutate: () => void;
}) => {
  const isEdit = initialValues.id !== "-1";

  const { setAlertInfo } = useAlert();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFormSubmit = async (values: D365EmailRecipient) => {
    setIsLoading(true);

    if (values.id === "-1") {
      await createD365EmailRecipient({
        body: values,
        onSuccess: () => {
          mutate();
          handleClose();
        },
        setAlertInfo,
      });
    } else {
      await updateD365EmailRecipient({
        recipientId: values.id,
        body: values,
        onSuccess: () => {
          mutate();
          handleClose();
        },
        setAlertInfo,
      });
    }

    setIsLoading(false);
  };

  return (
    <CustomModal open={open} handleClose={() => {}} wider>
      <IconButton
        sx={{
          position: "absolute",
          top: 4,
          right: 4,
          color: "text.primary",
          opacity: 0.5,
        }}
        size="small"
        disableRipple
        onClick={handleClose}
        disabled={isLoading}
      >
        <CloseIcon fontSize="small" />
      </IconButton>

      <Typography
        variant="h2"
        sx={{
          fontWeight: "bold",
          lineHeight: 1.25,
          fontSize: "1.125rem",
          mt: 0.25,
          mb: 2,
          color: "text.primary",
        }}
      >
        {isEdit ? "Edit" : "New"} Recipient
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={RecipientSchema}
        onSubmit={handleFormSubmit}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Field id="email" name="email">
                {() => (
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    required={!isEdit}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    helperText={
                      errors?.email && touched?.email && String(errors?.email)
                    }
                    error={Boolean(errors?.email && touched?.email)}
                    inputProps={{
                      readOnly: isEdit,
                    }}
                  />
                )}
              </Field>

              <Field id="is_active" name="is_active">
                {() => (
                  <TextField
                    fullWidth
                    select
                    id="is_active"
                    name="is_active"
                    label="Active"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.is_active}
                    helperText={
                      errors?.is_active &&
                      touched?.is_active &&
                      String(errors?.is_active)
                    }
                    error={Boolean(errors?.is_active && touched?.is_active)}
                  >
                    <MenuItem value={"true"}>Yes</MenuItem>
                    <MenuItem value={"false"}>No</MenuItem>
                  </TextField>
                )}
              </Field>
            </Box>

            <Button
              variant="contained"
              fullWidth
              disableElevation
              sx={{
                mt: 2,
                fontSize: 16,
                textTransform: "none",
                backgroundColor: "primary.main",
                py: 1.25,
              }}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress
                  size={28}
                  sx={{ color: "rgba(0, 0, 0, 0.26)" }}
                />
              ) : (
                `${isEdit ? "Update" : "Add"} Recipient`
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default D365EmailRecipientForm;
