import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  Box,
  Container,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { SIDEBAR_LINKS } from "../../constants/navigation";
import DrawerListItem from "./DrawerListItem";
import { useLocation } from "react-router-dom";
import { APP_NAME } from "../../constants/app.consts";

const MobileDrawer = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const { pathname } = useLocation();

  return (
    <Drawer
      variant="temporary"
      anchor="left"
      open={open}
      onClose={handleClose}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        display: { xs: "block", md: "none" },
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          backgroundColor: "background.paper",
        }}
      >
        <Container
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <img
                src={"/logo192.png"}
                alt={"loading-logo"}
                loading="lazy"
                width={32}
                height={32}
              />
              <Typography
                sx={{ fontSize: 16, color: "text.primary", fontWeight: 600 }}
              >
                {APP_NAME}
              </Typography>
            </Box>

            <IconButton
              onClick={handleClose}
              sx={{
                ml: "auto",
                color: "primary.main",
              }}
              edge="end"
            >
              <ClearOutlinedIcon fontSize="small" color="primary" />
            </IconButton>
          </Toolbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              borderTop: "1px solid",
              borderColor: "divider",
              py: {
                xs: 2,
                sm: 3,
              },
              gap: 1.5,
            }}
          >
            {SIDEBAR_LINKS.map((link, index) => {
              return (
                <DrawerListItem
                  key={`mobile-drawer-item-${index}`}
                  selected={pathname === link.href}
                  isDrawerMinimized={!open}
                  link={link}
                />
              );
            })}
          </Box>
        </Container>
        <Box
          sx={{
            borderTop: "1px solid",
            borderColor: "divider",
            backgroundColor: "background.dark",
            py: {
              xs: 2,
              sm: 3,
            },
          }}
        >
          <Typography
            sx={{
              pb: 2,
              fontSize: 12,
              textAlign: "center",
              display: open ? "block" : "none",
            }}
          >
            Copyright © {new Date().getFullYear()} | {APP_NAME}
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};

export default MobileDrawer;
