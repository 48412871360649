import useSWR from "swr";
import api from "./api";
import { createFetcher } from "./fetcher";
// import { CustomCreateUser } from "../interfaces/auth";
import { User } from "../interfaces/user";
import { alertSuccess, alertError } from "../utils/alert";
import { APIWithPagination } from "../interfaces/pagination";

export const useAllUsers = ({
  page = 0,
  pageSize = 25,
  search,
  ordering,
}: APIWithPagination & { isTenant?: boolean }) => {
  let url = `/users/list`;

  url += `?page=${page + 1}&page_size=${pageSize}`;

  if (search !== undefined) {
    url += `&search=${search}`;
  }

  if (ordering !== undefined) {
    url += `&ordering=${ordering}`;
  }

  const snapshot = useSWR<{
    count: number;
    next: string;
    previous: string;
    results: User[];
    total_pages: number;
  }>(url, createFetcher, {
    revalidateOnMount: true,
  });

  return { ...snapshot };
};

export const addUsers = async ({
  users,
  onSuccess,
  setAlertInfo,
}: {
  users: User[];
  onSuccess: () => void;
  setAlertInfo: (value: any) => void;
}) => {
  const url = `/users/create`;

  try {
    await api.post(url, users);

    alertSuccess(`User${users.length > 1 ? "s" : ""} added!`, setAlertInfo);
    onSuccess();
  } catch (error: any) {
    alertError(
      error?.response?.status === 401
        ? "Please login first."
        : error.response.data?.[0].email?.[0] ??
            error.response.data.detail ??
            error.response.data.message ??
            error.toString(),
      setAlertInfo
    );
  }
};

export const updateUser = async ({
  userId,
  body,
  onSuccess,
  setAlertInfo,
}: {
  userId: User;
  body: Partial<User>;
  onSuccess: () => void;
  setAlertInfo: (value: any) => void;
}) => {
  const url = `/users/${userId}/update`;

  try {
    await api.patch(url, body);

    alertSuccess(
      `User "${body.name}" updated!`,
      setAlertInfo
    );
    onSuccess();
  } catch (error: any) {
    alertError(
      error?.response?.status === 401
        ? "Please login first."
        : error.response.data.detail ??
            error.response.data.message ??
            error.toString(),
      setAlertInfo
    );
  }
};

export const deleteUser = async ({
  user,
  onSuccess,
  setAlertInfo,
}: {
  user: User;
  onSuccess: () => void;
  setAlertInfo: (value: any) => void;
}) => {
  const url = `/users/${user.id}/delete`;

  try {
    await api.delete(url);

    alertSuccess(`User "${user.name}" deleted!`, setAlertInfo);
    onSuccess();
  } catch (error: any) {
    alertError(
      error?.response?.status === 401
        ? "Please login first."
        : error.response.data.detail ??
            error.response.data.message ??
            error.toString(),
      setAlertInfo
    );
  }
};
