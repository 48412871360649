import useSWR from "swr";
import { D365Account, D365EmailRecipient } from "../interfaces/d365";
import { createFetcher } from "./fetcher";
import api from "./api";
import { alertError, alertSuccess } from "../utils/alert";

export const useAllD365Accounts = () => {
  let url = `/d365/accounts/`;

  const snapshot = useSWR<{
    count: number;
    next: string;
    previous: string;
    results: D365Account[];
    total_pages: number;
  }>(url, createFetcher, {
    revalidateOnMount: true,
  });
  return { ...snapshot };
};

export const createD365Account = async ({
  body,
  onSuccess,
  setAlertInfo,
}: {
  body: D365Account;
  onSuccess: () => void;
  setAlertInfo: (value: any) => void;
}) => {
  const url = `/d365/accounts/`;

  try {
    await api.post(url, body);

    onSuccess();
  } catch (error: any) {
    alertError(
      error?.response?.status === 401
        ? "Please login first."
        : error.response.data?.[0].email?.[0] ??
            error.response.data.detail ??
            error.response.data.message ??
            error.toString(),
      setAlertInfo
    );
  }
};

export const updateD365Account = async ({
  accountId,
  body,
  onSuccess,
  setAlertInfo,
}: {
  accountId: string;
  body: Partial<D365Account>;
  onSuccess: () => void;
  setAlertInfo: (value: any) => void;
}) => {
  const url = `/d365/accounts/${accountId}/`;

  try {
    await api.patch(url, body);

    onSuccess();
  } catch (error: any) {
    alertError(
      error?.response?.status === 401
        ? "Please login first."
        : error.response.data?.[0].email?.[0] ??
            error.response.data.detail ??
            error.response.data.message ??
            error.toString(),
      setAlertInfo
    );
  }
};

export const useAllD365EmailRecipients = () => {
  let url = `/d365/recipients/`;

  const snapshot = useSWR<{
    count: number;
    next: string;
    previous: string;
    results: D365EmailRecipient[];
    total_pages: number;
  }>(url, createFetcher, {
    revalidateOnMount: true,
  });
  return { ...snapshot };
};

export const createD365EmailRecipient = async ({
  body,
  onSuccess,
  setAlertInfo,
}: {
  body: D365EmailRecipient;
  onSuccess: () => void;
  setAlertInfo: (value: any) => void;
}) => {
  const url = `/d365/recipients/`;

  try {
    await api.post(url, body);

    onSuccess();
  } catch (error: any) {
    alertError(
      error?.response?.status === 401
        ? "Please login first."
        : error.response.data?.[0].email?.[0] ??
            error.response.data.detail ??
            error.response.data.message ??
            error.toString(),
      setAlertInfo
    );
  }
};

export const updateD365EmailRecipient = async ({
  recipientId,
  body,
  onSuccess,
  setAlertInfo,
}: {
  recipientId: string;
  body: Partial<D365EmailRecipient>;
  onSuccess: () => void;
  setAlertInfo: (value: any) => void;
}) => {
  const url = `/d365/recipients/${recipientId}/`;

  try {
    await api.patch(url, body);

    onSuccess();
  } catch (error: any) {
    alertError(
      error?.response?.status === 401
        ? "Please login first."
        : error.response.data?.[0].email?.[0] ??
            error.response.data.detail ??
            error.response.data.message ??
            error.toString(),
      setAlertInfo
    );
  }
};

export const deleteD365EmailRecipient = async ({
  recipientId,
  onSuccess,
  setAlertInfo,
}: {
  recipientId: string;
  onSuccess: () => void;
  setAlertInfo: (value: any) => void;
}) => {
  const url = `/d365/recipients/${recipientId}/`;

  try {
    await api.delete(url);

    onSuccess();
  } catch (error: any) {
    alertError(
      error?.response?.status === 401
        ? "Please login first."
        : error.response.data?.[0].email?.[0] ??
            error.response.data.detail ??
            error.response.data.message ??
            error.toString(),
      setAlertInfo
    );
  }
};

export const runReports = async ({
  body,
  setAlertInfo,
}: {
  body: any;
  setAlertInfo: (value: any) => void;
}) => {
  const url = `/d365/run-docker/`;

  try {
    let response = await api.post(url, body);

    if (response.status === 200) {
      alertSuccess("Docker run was successful!", setAlertInfo);
    } else {
      alertError("Failed to run Docker.", setAlertInfo);
    }
  } catch (error: any) {
    alertError("Error running Docker.", setAlertInfo);
  }
};
