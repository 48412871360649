export interface User {
  id?: number;
  name: string;
  email: string;
  password?: string;
  created_at?: string;
  role?: string;
}

export interface AuthState {
  user?: User;
  setUser: (value: any) => void;
}

export const initialState: User = {
  id: -1,
  name: "",
  email: "",
  created_at: "",
};
