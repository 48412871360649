import { GridColDef, GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import { PaginationModel } from "../../interfaces/pagination";
import { Log } from "../../interfaces/log";
import Table, { Text } from "../Table";
import { formatDateTime } from "../../utils/date.format.utils";
import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const LogsTable = ({
  logs,
  rowCount,
  paginationModel,
  setPaginationModel,
  handleFilterModelChange,
  handleSortModelChange,
  isLoading,
}: {
  logs: Log[];
  rowCount: number;
  paginationModel: PaginationModel;
  setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModel>>;
  handleFilterModelChange: (filterModel: GridFilterModel) => void;
  handleSortModelChange: (sortModel: GridSortModel) => void;
  isLoading: boolean;
}) => {
  const TRUCATE_LENGTH = 100;
  const [showAll, setShowAll] = useState<Map<string, boolean>>(new Map());

  useEffect(() => {
    // Initialize showAll state with logs id as key and false as value
    const showAllMap = new Map<string, boolean>();
    logs.forEach((log) => {
      if (log.id) {
        showAllMap.set(log.id.toString(), false);
      }
    });
    setShowAll(showAllMap);
  }, [logs]);

  const truncate = (str: string, index: number) => {
    return str.length > TRUCATE_LENGTH ? (
      <div>
        <Text
          text={
            showAll.get(index.toString())
              ? str
              : str.substring(0, TRUCATE_LENGTH) + "..."
          }
        />
        <Button
          onClick={() => {
            setShowAll(
              new Map(
                showAll.set(index.toString(), !showAll.get(index.toString()))
              )
            );
          }}
          size="small"
        >
          {showAll.get(index.toString()) ? "Show less" : "Show more"}
        </Button>
      </div>
    ) : (
      <Text text={str || "-"} />
    );
  };

  const columns: GridColDef<Log>[] = [
    {
      field: "index",
      headerName: "No.",
      sortable: false,
      disableColumnMenu: true,
      width: 45,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <Text text={`${params.value}.`} />,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "created_at",
      headerName: "Created At",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => formatDateTime(params.value),
      renderCell: (params) => (
        <Typography>
          {params.value ? formatDateTime(params.value) : "-"}
        </Typography>
      ),
    },
    {
      field: "completed_at",
      headerName: "Completed At",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => formatDateTime(params.value),
      renderCell: (params) => (
        <Typography>
          {params.value ? formatDateTime(params.value) : "-"}
        </Typography>
      ),
    },
    {
      field: "is_success",
      headerName: "Status",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "output",
      headerName: "Output",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => truncate(params.value, params.row.id!),
    },
    // {
    //   field: "job",
    //   headerName: "Job",
    //   minWidth: 150,
    //   flex: 1,
    //   renderCell: (params) => <Text text={params.value || "-"} />,
    // },
  ];

  return (
    <>
      <Table
        rows={logs.map((log, index) => ({
          ...log,
          index: paginationModel.page * paginationModel.pageSize + (index + 1),
        }))}
        columns={columns}
        loading={isLoading}
        sx={{
          display: "grid",
          ".css-axafay-MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
        }}
        autoHeight={false}
        rowCount={rowCount}
        pageSizeOptions={[25, 50, 100]}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        filterMode="server"
        onFilterModelChange={handleFilterModelChange}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
      />
    </>
  );
};

export default LogsTable;
