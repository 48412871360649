import { Box, Grid } from "@mui/material";
import UserProfile from "../components/Setting/UserProfile";
import Setting from "../components/Setting/Setting";
import PageTitle from "../components/PageTitle";

const SettingPage = () => {
  return (
    <>
      <PageTitle title="Setting"/>
      <Box
        sx={{
          flex: 1,
          bgcolor: "background.default",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <UserProfile />
          </Grid>
          <Grid item xs={12} lg={8}>
            <Box
              sx={{
                backgroundColor: "background.paper",
                padding: { lg: 2, xs: 1 },
                borderRadius: 1,
              }}
            >
              <Setting />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SettingPage;
