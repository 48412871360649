import {
  Box,
  Button,
  Divider,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { APP_NAME } from "../../constants/app.consts";
import { useNavigate } from "react-router-dom";
import useAlert from "../../hooks/useAlert";
import { alertSuccess, alertError } from "../../utils/alert";
import { signIn } from "../../services/auth";
import useAuth from "../../hooks/useAuth";
import { clearLocalStorage } from "../../utils/auth";

const initialValues = {
  email: null,
  password: null,
};

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email address"
    )
    .required("Please enter your email address"),

  password: Yup.string().required("Please enter your password"),
});

const LoginForm = () => {
  const navigate = useNavigate();
  const { setAlertInfo } = useAlert();
  const { setUser } = useAuth();

  useEffect(() => {
    clearLocalStorage();
    setUser(undefined); 
  }, [setUser]);

  const [isLoading, setIsLoading] = useState(false);

const handleFormSubmit = async (values: any) => {
  setIsLoading(true);

  try {
    await signIn({
      username: values.email,
      password: values.password,
      onSuccess: async () => {
        alertSuccess("Welcome back!", setAlertInfo);
        navigate("/setting", { replace: true });
      },
      setAlertInfo,
      setUser,
    });
  } catch (error) {
    // Handle error if signIn fails
    alertError("Failed to sign in.", setAlertInfo);
  } finally {
    setIsLoading(false);
  }
};

  // const handleGoogleSignIn = async () => {
  //   alertSuccess("Welcome back!", setAlertInfo);
  //   navigate("/users", { replace: true });
  // };

  // const handleMicrosoftSignIn = async () => {
  //   alertSuccess("Welcome back!", setAlertInfo);
  //   navigate("/users", { replace: true });
  // };

  return (
    <Box
      sx={{
        width: 350,
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        margin: "auto",
        alignItems: "center",
      }}
    >
      <img
        src={"/logo192.png"}
        alt={"loading-logo"}
        loading="lazy"
        width={48}
        height={48}
      />

      <Typography
        sx={{
          textAlign: "center",
          marginBottom: 4,
          fontSize: 20,
          color: "text.primary",
        }}
      >
        Sign in to {APP_NAME}
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={LoginSchema}
        onSubmit={handleFormSubmit}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Field id="email" name="email">
              {(props: any) => (
                <TextField
                  size="small"
                  fullWidth
                  id="email"
                  name="email"
                  label="Email *"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email ?? ""}
                  placeholder="user@example.com"
                  helperText={
                    errors?.email && touched?.email && String(errors?.email)
                  }
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    marginBottom: 3,
                  }}
                  error={Boolean(errors?.email && touched?.email)}
                />
              )}
            </Field>
            <Field id="password" name="password">
              {(props: any) => (
                <TextField
                  size="small"
                  fullWidth
                  id="password"
                  name="password"
                  label="Password *"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password ?? ""}
                  placeholder="********"
                  helperText={
                    errors?.password &&
                    touched?.password &&
                    String(errors?.password)
                  }
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    marginBottom: 2,
                  }}
                  type="password"
                  error={Boolean(errors?.password && touched?.password)}
                />
              )}
            </Field>
            <Box
              sx={{
                marginBottom: 3,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Link
                href={isLoading ? "#" : "/forget-password"}
                underline="none"
                variant="caption"
                sx={{
                  color: isLoading ? "text.disabled" : "primary.main",
                  fontWeight: 500,
                }}
              >
                {"Forget Password?"}
              </Link>
            </Box>

            <Button
              variant="contained"
              fullWidth
              disabled={isLoading}
              sx={{
                backgroundColor: "primary.main",
                ":hover": { backgroundColor: "primary.main" },
                marginTop: 2,
                marginBottom: 3,
                paddingY: 1,
              }}
              type="submit"
            >
              Login
            </Button>
            {/* <Divider>
              <Typography
                sx={{
                  color: "text.primary",
                  fontSize: 16,
                  fontWeight: 400,
                }}
              >
                OR
              </Typography>
            </Divider>
            <Button
              variant="outlined"
              fullWidth
              disabled={isLoading}
              sx={{
                marginTop: 3,
                marginBottom: 2,
                paddingY: 1,
              }}
              onClick={handleGoogleSignIn}
            >
              <Box
                component="img"
                sx={{
                  width: 24,
                  height: 24,
                  display: "block",
                  overflow: "hidden",
                  marginRight: 1,
                }}
                src={"/icons/icons8-google-48.png"}
                alt={`google-signin-button`}
              />
              Continue with Google
            </Button>
            <Button
              variant="outlined"
              disabled={isLoading}
              fullWidth
              sx={{
                marginBottom: 4,
                paddingY: 1,
              }}
              onClick={handleMicrosoftSignIn}
            >
              <Box
                component="img"
                sx={{
                  width: 24,
                  height: 24,
                  display: "block",
                  overflow: "hidden",
                  marginRight: 1,
                }}
                src={"/icons/icons8-microsoft-48.png"}
                alt={`microsoft-signin-button`}
              />
              Continue with Microsoft
            </Button> */}
            <Box sx={{}}>
              <Typography
                sx={{
                  fontSize: 14,
                  color: "text.secondary",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                {"Don't have an account yet? "}
                <Link
                  href={isLoading ? "#" : "/signup"}
                  underline="none"
                  sx={{
                    color: isLoading ? "text.disabled" : "primary.main",
                    fontWeight: 600,
                  }}
                >
                  {"Free Sign Up"}
                </Link>
              </Typography>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default LoginForm;
