import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const RootLayout = () => (
  <Box
    sx={{
      display: "flex",
      minHeight: "100vh",
      scrollBehavior: "smooth",
      bgcolor: "white",
      overflowX: "hidden",
    }}
  >
    <Outlet />
  </Box>
);

export default RootLayout;
