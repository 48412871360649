import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { D365Account } from "../../interfaces/d365";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import { Field, Form, Formik } from "formik";

import * as Yup from "yup";
import { alertError, alertSuccess } from "../../utils/alert";
import { createD365Account, updateD365Account } from "../../services/d365";
import useAlert from "../../hooks/useAlert";

const AccountSchema = Yup.object().shape({
  account_type: Yup.string().required("Please select an account type"),
  username: Yup.string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email address"
    )
    .required("Please enter an email address"),
  password: Yup.string().required("Please enter a password"),
});

const D365AccountCard = ({
  data,
  mutate,
}: {
  data: D365Account;
  mutate: () => void;
}) => {
  const { setAlertInfo } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleFormSubmit = async (values: any) => {
    setIsLoading(true);
    if (values.id === "-1") {
      try {
        await createD365Account({
          body: values,
          onSuccess: async () => {
            alertSuccess("Account Added!", setAlertInfo);
          },
          setAlertInfo,
        });
      } catch (error) {
        // Handle error if signIn fails
        alertError("Failed to add account.", setAlertInfo);
      } finally {
        mutate();
        setIsLoading(false);
        return;
      }
    }

    try {
      await updateD365Account({
        accountId: data.id,
        body: values,
        onSuccess: async () => {
          alertSuccess("Account Updated!", setAlertInfo);
        },
        setAlertInfo,
      });
    } catch (error) {
      // Handle error if signIn fails
      alertError("Failed to update account.", setAlertInfo);
    } finally {
      mutate();
      setIsLoading(false);
    }
  };

  return (
    <Card sx={{ minWidth: 650 }}>
      {data.account_type !== "" && (
        <CardHeader
          title={data.id === "-1" ? "Add new Account" : data.account_type}
        />
      )}
      <CardContent>
        <Formik
          initialValues={data}
          validationSchema={AccountSchema}
          onSubmit={handleFormSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                {data.id === "-1" && (
                  <Field id="account_type" name="account_type">
                    {() => (
                      <TextField
                        fullWidth
                        select
                        id="account_type"
                        name="account_type"
                        label="Account Type"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.account_type}
                        helperText={
                          errors?.account_type &&
                          touched?.account_type &&
                          String(errors?.account_type)
                        }
                        error={Boolean(
                          errors?.account_type && touched?.account_type
                        )}
                      >
                        <MenuItem value="PowerApps">PowerApps</MenuItem>
                        <MenuItem value="Encodian">Encodian</MenuItem>
                      </TextField>
                    )}
                  </Field>
                )}

                <Field id="username" name="username">
                  {() => (
                    <TextField
                      size="small"
                      fullWidth
                      id="username"
                      name="username"
                      label="Email *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username ?? ""}
                      placeholder="user@example.com"
                      helperText={
                        errors?.username &&
                        touched?.username &&
                        String(errors?.username)
                      }
                      InputLabelProps={{ shrink: true }}
                      error={Boolean(errors?.username && touched?.username)}
                    />
                  )}
                </Field>
                <Field id="password" name="password">
                  {() => (
                    <OutlinedInput
                      size="small"
                      fullWidth
                      id="password"
                      name="password"
                      label="Password *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password ?? ""}
                      placeholder="********"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      error={Boolean(errors?.password && touched?.password)}
                    />
                  )}
                </Field>

                <Button
                  variant="contained"
                  fullWidth
                  disabled={
                    isLoading ||
                    (data.username === values.username &&
                      data.password === values.password)
                  }
                  sx={{
                    backgroundColor: "primary.main",
                    ":hover": { backgroundColor: "primary.main" },
                    marginTop: 2,
                    marginBottom: 3,
                    paddingY: 1,
                  }}
                  type="submit"
                >
                  {data.id === "-1" ? "Add" : "Update"}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default D365AccountCard;
