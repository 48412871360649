import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import useModal from "../../hooks/useModal";
import CheckEmailDialog from "./CheckEmailDialog";

const initialValues = {
  email: null,
};

const ForgetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email address"
    )
    .required("Please enter your email address"),
});

const ForgetPasswordForm = () => {
  const { open, handleOpen, handleClose } = useModal();

  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values: any) => {
    setIsLoading(true);

    setIsLoading(false);

    handleOpen();
  };

  return (
    <>
      <Box
        sx={{
          width: 350,
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 1,
          margin: "auto",
          alignItems: "center",
        }}
      >
        <img
          src={"/logo192.png"}
          alt={"loading-logo"}
          loading="lazy"
          width={48}
          height={48}
        />

        <Typography
          sx={{ textAlign: "center", fontSize: 20, color: "text.primary" }}
        >
          Forget Password?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: 14,
            color: "text.secondary",
            marginBottom: 6,
          }}
        >
          No worries, please enter your email below to receive password reset
          instructions.
        </Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={ForgetPasswordSchema}
          onSubmit={handleFormSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              {values.email && (
                <CheckEmailDialog
                  email={values.email}
                  open={open}
                  handleClose={handleClose}
                />
              )}
              <Field id="email" name="email">
                {(props: any) => (
                  <TextField
                    size="small"
                    fullWidth
                    id="email"
                    name="email"
                    label="Email *"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email ?? ""}
                    placeholder="user@example.com"
                    helperText={
                      errors?.email && touched?.email && String(errors?.email)
                    }
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      marginBottom: 3,
                    }}
                    error={Boolean(errors?.email && touched?.email)}
                  />
                )}
              </Field>
              <Button
                variant="contained"
                fullWidth
                disabled={isLoading}
                sx={{
                  backgroundColor: "primary.main",
                  ":hover": { backgroundColor: "primary.main" },

                  marginBottom: 3,
                  paddingY: 1,
                }}
                type="submit"
              >
                Reset Password
              </Button>
              <Button
                variant="text"
                fullWidth
                disabled={isLoading}
                startIcon={<KeyboardBackspaceRoundedIcon />}
                sx={{
                  marginTop: 2,
                  marginBottom: 3,
                  paddingY: 1,
                }}
                href="/login"
              >
                Back to Login
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default ForgetPasswordForm;
