import { Box, Typography } from "@mui/material";

type ProfileInformationListItemProps = {
  label: string;
  text: string;
};

const ProfileInformationListItem = ({
  label,
  text,
}: ProfileInformationListItemProps) => {
  return (
    <Box sx={{ width: "100%", alignItems: "start", marginBottom: 2 }}>
      <Typography
        variant="caption"
        sx={{ fontWeight: 500, color: "primary.main" }}
      >
        {label}
      </Typography>
      <Typography sx={{ fontWeight: 700, color: "text.primary" }}>
        {text}
      </Typography>
    </Box>
  );
};

export default ProfileInformationListItem;
