import React from "react";
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import { CustomModal } from "../CustomModal";

const D365EmailRecipientDialog = ({
  open,
  handleClose,
  title,
  body,
  buttonText,
  buttonOnClick,
  isButtonLoading = false,
  haveCancelButton = true,
}: {
  open: boolean;
  handleClose: () => void;
  title: string;
  body: string | React.ReactNode;
  buttonText: string;
  buttonOnClick: (event: React.MouseEvent<HTMLElement>) => void;
  isButtonLoading?: boolean;
  haveCancelButton?: boolean;
}) => (
  <CustomModal
    open={open}
    handleClose={isButtonLoading ? () => {} : handleClose}
  >
    <Typography
      sx={{
        fontSize: { xs: "1rem", md: "1.25rem" },
        fontWeight: "bold",
        mb: 2,
      }}
    >
      {title}
    </Typography>

    {typeof body === "string" ? (
      <Typography sx={{ fontStyle: "italic", fontSize: { xs: 14, md: 16 } }}>
        {body}
      </Typography>
    ) : (
      body
    )}

    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 1.5,
        mt: 3,
      }}
    >
      {haveCancelButton && (
        <Button
          variant="text"
          sx={{
            textTransform: "none",
            color: "text.primary",
            p: 0,
            background: "transparent !important",
            fontSize: { xs: 14, md: 16 },
          }}
          disableRipple
          onClick={handleClose}
          disabled={isButtonLoading}
        >
          Cancel
        </Button>
      )}

      <Button
        variant="contained"
        disableElevation
        sx={{
          textTransform: "none",
          fontSize: { xs: 14, md: 16 },
          py: 1.25,
          px: 4,
          gap: 1,
        }}
        onClick={buttonOnClick}
        disabled={isButtonLoading}
      >
        {isButtonLoading && (
          <CircularProgress
            size={18}
            sx={{
              color: "rgba(0, 0, 0, 0.26)",
            }}
          />
        )}

        {isButtonLoading ? "Loading" : buttonText}
      </Button>
    </Box>
  </CustomModal>
);

export default D365EmailRecipientDialog;
