import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import useAlert from "../../hooks/useAlert";
import { alertSuccess } from "../../utils/alert";
import { Job } from "../../interfaces/jobs";
import ProfileInformationListItem from "./ProfileInformationListItem";

type ViewJobDialogProps = {
  job: Job;
  open: boolean;
  handleClose: () => void;
};

const ViewJobDialog = React.forwardRef<HTMLDivElement, ViewJobDialogProps>(
  function EditJobDialog(props, ref) {
    const { job, open, handleClose } = props;

    const { setAlertInfo } = useAlert();

    const [isLoading, setisLoading] = useState(false);

    const handleFormSubmit = async (values: any) => {
      setisLoading(true);
      alertSuccess("Edit job success.", setAlertInfo);
      setisLoading(false);
      handleClose();
    };

    const recurrenceMapping: Record<string, string> = {
      '0 0 * * *': 'Daily',
      '0 0 * * 0': 'Weekly',
      '0 0 1 * *': 'Monthly',
      '0 0 1 1 *': 'Yearly',
      '': 'Never',
    };

    return (
      <Modal ref={ref} open={open}>
        <div>
          <Formik
            initialValues={{
              id: job.id,
              name: job.name,
              recurrence: job.recurrence,
              last_triggered_at: job.last_triggered_at,
              log_count: job.log_count,
            }}
            onSubmit={handleFormSubmit}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: "80%",
                    width: 350,
                    backgroundColor: "background.paper",
                    borderRadius: 2,
                    paddingX: 4,
                    paddingY: 4,
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box sx={{ marginBottom: 2, display: "flex" }}>
                    <Typography variant="h6" sx={{ color: "text.primary" }}>
                      View Job
                    </Typography>
                  </Box>

                  <ProfileInformationListItem label={"Name"} text={values.name || "-"} />
                  <ProfileInformationListItem label="Recurrence" text={recurrenceMapping[values.recurrence] || "Never"} />
                  <ProfileInformationListItem label={"Last Triggered At"} text={values.last_triggered_at || "-"} />
                  <ProfileInformationListItem label={"Number Count"} text={values.log_count || "-"} />
                  <Box sx={{ display: "flex", marginTop: 2 }}>
                    <Button
                      fullWidth
                      size="small"
                      disabled={isLoading}
                      variant="outlined"
                      disableElevation
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Close
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    );
  }
);

export default ViewJobDialog;
