import { Box, Button, Modal, TextField, Typography, MenuItem } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import useAlert from "../../hooks/useAlert";
import { alertSuccess } from "../../utils/alert";
import { Job } from "../../interfaces/jobs";
import { updateJob } from "../../services/jobs";

type EditJobDialogProps = {
  job: Job;
  open: boolean;
  handleClose: () => void;
  mutate: () => void;
};

const EditJobSchema = Yup.object().shape({
  name: Yup.string().required("Please enter contact name"),
  recurrence: Yup.string().required("Please select recurrence"),
});

const contracts = [
  "Daily",
  "Weekly",
  "Monthly",
  "Yearly",
  "Never",
];

const EditJobDialog = React.forwardRef<HTMLDivElement, EditJobDialogProps>(
  function EditJobDialog(props, ref) {
    const { job, open, handleClose, mutate } = props;

    const { setAlertInfo } = useAlert();

    const [isLoading, setisLoading] = useState(false);

    const handleFormSubmit = async (values: any) => {
      setisLoading(true);

      await updateJob({
        jobId: values.id,
        body: {
          name: values.name,
          recurrence: values.recurrence,
        },
        onSuccess: () => {
          mutate();
          handleClose();
        },
        setAlertInfo,
      });

      setisLoading(false);
    };

    return (
      <Modal ref={ref} open={open}>
        <div>
          <Formik
            initialValues={{
              id: job.id,
              name: job.name,
              recurrence: job.recurrence,
            }}
            validationSchema={EditJobSchema}
            onSubmit={handleFormSubmit}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: "80%",
                    width: 350,
                    backgroundColor: "background.paper",
                    borderRadius: 2,
                    paddingX: 4,
                    paddingY: 4,
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box sx={{ marginBottom: 2, display: "flex" }}>
                    <Typography variant="h6" sx={{ color: "text.primary" }}>
                      Edit Job
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      mt: 4,
                      mb: 5,
                      display: "flex",
                      flexDirection: "column",
                      gap: 3,
                    }}
                  >
                    <Field id="name" name="name">
                      {({ }) => (
                        <TextField
                          variant="standard"
                          size="small"
                          fullWidth
                          id="name"
                          name="name"
                          label="Name *"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          placeholder="e.g. John Doe"
                          InputLabelProps={{ shrink: true }}
                          helperText={
                            errors?.name &&
                            touched?.name &&
                            String(errors?.name)
                          }
                          error={Boolean(
                            errors?.name && touched?.name
                          )}
                        />
                      )}
                    </Field>

                    <Field id="recurrence" name="recurrence">
                      {() => (
                        <TextField
                          select
                          variant="standard"
                          size="small"
                          fullWidth
                          id="recurrence"
                          name="recurrence"
                          label="Recurrence *"
                          value={values.recurrence}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.recurrence && touched.recurrence && errors.recurrence}
                          error={Boolean(errors.recurrence && touched.recurrence)}
                        >
                          {contracts.map((contract) => (
                            <MenuItem key={contract} value={contract}>
                              {contract}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </Field>
                  </Box>

                  <Box sx={{ display: "flex", marginTop: 2 }}>
                    <Button
                      fullWidth
                      size="small"
                      disabled={isLoading}
                      variant="outlined"
                      disableElevation
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Box width={32} />
                    <Button
                      fullWidth
                      size="small"
                      disabled={isLoading}
                      variant="contained"
                      disableElevation
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    );
  }
);

export default EditJobDialog;
