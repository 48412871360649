import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import useAlert from "../../hooks/useAlert";
import { alertSuccess } from "../../utils/alert";
import { Storage } from "../../interfaces/storage";
import { useStorageInfo } from "../../services/storages";

type ViewStorageDialogProps = {
  storage: Storage;
  open: boolean;
  handleClose: () => void;
};

const ViewStorageDialog = React.forwardRef<
  HTMLDivElement,
  ViewStorageDialogProps
>(function ViewStorageDialog(props, ref) {
  const { storage, open, handleClose } = props;

  const { setAlertInfo } = useAlert();

  const { data: data, isLoading, error } = useStorageInfo(Number(storage.id));

  return (
    <Modal ref={ref} open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "80%",
          width: 350,
          backgroundColor: "background.paper",
          borderRadius: 2,
          paddingX: 4,
          paddingY: 4,
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box sx={{ marginBottom: 2, display: "flex" }}>
          <Typography variant="h6" sx={{ color: "text.primary" }}>
            Storage Details
          </Typography>
        </Box>

        {data && (
          <>
            <ListItem label="ID" text={data.id || "-"} />
            <ListItem label="Name" text={data.username || "-"} />
            <ListItem label="Password" text={data.password || "-"} />
          </>
        )}
        <Box sx={{ display: "flex", marginTop: 2 }}>
          <Button
            fullWidth
            size="small"
            disabled={isLoading}
            variant="outlined"
            disableElevation
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});

const ListItem = ({
  label,
  text,
}: {
  label: string;
  text: string | number;
}) => {
  return (
    <Box sx={{ width: "100%", alignItems: "start", marginBottom: 2 }}>
      <Typography
        variant="caption"
        sx={{ fontWeight: 500, color: "primary.main", fontSize: 16 }}
      >
        {label}
      </Typography>
      <Typography sx={{ fontWeight: 700, color: "text.primary", fontSize: 16 }}>
        {text}
      </Typography>
    </Box>
  );
};

export default ViewStorageDialog;
