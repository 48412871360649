import React from "react";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { Button, Box } from "@mui/material";

interface RunSelectedButtonProps {
  onClick: () => void; // Explicitly define the type of onClick prop
}

const RunSelectedButton: React.FC<RunSelectedButtonProps> = ({ onClick }) => {
  return (
    <Button
      size="small"
      variant="contained"
      startIcon={<DirectionsRunIcon />}
      onClick={onClick}
    >
      Run Selected
    </Button>
  );
};

export default RunSelectedButton;
