import React from "react";
import { Backdrop, Box, Fade, Modal, SxProps } from "@mui/material";

export const CustomModal = ({
  open,
  handleClose,
  children,
  wider = false,
  widest = false,
  sx,
}: {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  wider?: boolean;
  widest?: boolean;
  sx?: SxProps;
}) => (
  <Modal
    open={open}
    onClose={handleClose}
    closeAfterTransition
    disableAutoFocus={true}
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
    sx={{
      my: 2,
      mx: { xs: 2, lg: 12 },
    }}
  >
    <Fade in={open}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: widest ? "800px" : wider ? "500px" : "400px",
          backgroundColor: "background.default",
          borderRadius: 2,
          p: 2,
          maxHeight: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          "&:focus-visible": {
            outline: "none",
          },
          ...sx,
        }}
      >
        {children}
      </Box>
    </Fade>
  </Modal>
);
