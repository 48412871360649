import React from "react";
import {
  DataGrid,
  DataGridProps,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarProps,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, SxProps, Typography } from "@mui/material";

const Table = ({
  rows,
  columns,
  sx,
  slots,
  slotProps,
  isEmpty = false,
  ...props
}: DataGridProps & { isEmpty?: boolean }) => (
  <DataGrid
    rows={rows}
    columns={columns}
    initialState={{
      pagination: { paginationModel: { pageSize: 25 } },
    }}
    sx={{
      flex: "none",
      backgroundColor: "background.default",
      borderRadius: 1,
      overflow: "hidden",
      "& .MuiDataGrid-cell": {
        py: 1,
        borderBottom: "none",
      },
      "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
        {
          outline: "none",
        },
      "& .MuiDataGrid-columnHeader": {
        position: "relative",
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "bold",
        fontSize: 14,
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        overflow: "initial",
        position: "static",
      },
      "& .MuiDataGrid-columnHeader--emptyGroup": {
        borderBottom: "1px solid",
        borderColor: "divider",
      },
      "& .MuiDataGrid-virtualScroller": {
        flex: 1,
      },
      ...(isEmpty && {
        "& .MuiDataGrid-cellCheckbox": {
          display: "none",
        },
        "& .MuiDataGrid-virtualScrollerContent, & .MuiDataGrid-virtualScrollerRenderZone":
          {
            width: "100% !important",
          },
        "& .MuiDataGrid-row": {
          display: "block",
          width: "100%",
        },
        "& .MuiDataGrid-row:hover": {
          backgroundColor: "transparent",
        },
        "& .MuiDataGrid-cell": {
          p: 0,
          border: "none",
          minWidth: "0 !important",
          maxWidth: "100% !important",
        },
      }),
      ...sx,
    }}
    slots={{ toolbar: GridToolbar, ...slots }}
    slotProps={{
      ...slotProps,
      basePopper: {
        sx: {
          ".MuiPaper-root": {
            backgroundColor: "background.default",
          },
        },
        ...slotProps?.basePopper,
      },
      toolbar: {
        sx: {
          py: 0,
          pr: 0,
          pl: 1.5,
          borderBottom: "1px solid",
          borderColor: "divider",
          "& > button": {
            fontWeight: 500,
            textTransform: "none",
          },
          "& > .MuiTextField-root": {
            pb: 0,
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-input": {
              py: 1,
            },
          },
        },
        showQuickFilter: true,
        csvOptions: { disableToolbarButton: false },
        printOptions: { disableToolbarButton: true },
        ...slotProps?.toolbar,
      },
      columnsPanel: {
        disableHideAllButton: true,
        disableShowAllButton: true,
        ...slotProps?.columnsPanel,
      },
    }}
    getRowHeight={() => "auto"}
    density="compact"
    autoHeight
    disableRowSelectionOnClick
    disableColumnFilter
    disableDensitySelector
    {...props}
  />
);

type TextProps = {
  text: string;
  sx?: SxProps;
  onClick?: () => void;
};

export const Text = React.forwardRef<HTMLSpanElement, TextProps>(
  ({ text, sx, onClick, ...props }, ref) => (
    <Typography
      {...props}
      ref={ref}
      sx={{
        fontSize: 14,
        wordBreak: "break-word",
        color: "text.primary",
        ...sx,
      }}
      onClick={onClick}
    >
      {text}
    </Typography>
  )
);

Text.displayName = "Text";

type TextWithSubTextProps = TextProps & {
  subText: string;
  subTextSx?: SxProps;
};

export const TextWithSubText = ({
  text,
  subText,
  subTextSx,
  ...props
}: TextWithSubTextProps) => (
  <Box>
    <Text text={text} {...props} />
    <Text
      text={subText}
      sx={{ fontSize: 12, fontStyle: "italic", ...subTextSx }}
    />
  </Box>
);

export const ToolbarWithFilter = (props: GridToolbarProps) => (
  <GridToolbarContainer sx={props.sx}>
    <GridToolbarColumnsButton />
    {props.filters}
    <Box sx={{ flexGrow: 1 }} />
    <GridToolbarQuickFilter />
  </GridToolbarContainer>
);

export const ToolbarWithAddButton = (props: GridToolbarProps) => (
  <GridToolbarContainer sx={props.sx}>
    <GridToolbarColumnsButton />
    <Box sx={{ flexGrow: 1 }} />
    {props.children}
    <GridToolbarQuickFilter />
  </GridToolbarContainer>
);

interface ToolbarWithRunSelectedButtonProps {
  selectedRowCount: number;
  children: React.ReactNode;
  sx?: React.CSSProperties;
}

export const ToolbarWithRunSelectedButton: React.FC<
  ToolbarWithRunSelectedButtonProps
> = ({ selectedRowCount, children, sx }) => {
  return (
    <GridToolbarContainer sx={sx}>
      <GridToolbarColumnsButton />
      <Box sx={{ flexGrow: 1 }} />
      {selectedRowCount > 0 ? (
        <div>{children}</div>
      ) : (
        <div style={{ visibility: "hidden" }}>{children}</div>
      )}
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

export default Table;
