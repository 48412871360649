import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import KeyboardTabRoundedIcon from "@mui/icons-material/KeyboardTabRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { clearLocalStorage } from "../../utils/auth";
import useAuth from "../../hooks/useAuth";
import useModal from "../../hooks/useModal";

const UserAvatarIconButton = () => {
  const navigate = useNavigate();

  const { user, setUser } = useAuth();

  const { open, handleOpen, handleClose } = useModal();

  const handleLogout = () => {
    clearLocalStorage();
    setUser(undefined);
    navigate("/", { replace: true });
    handleClose();
  };

  return (
    <Tooltip
      title="Account of current user"
      // title={
      //   <Box>
      //     <MenuItem>
      //       <Box sx={{ display: "flex", flexDirection: "column" }}>
      //         <Typography
      //           sx={{
      //             fontSize: 14,
      //             fontWeight: 700,
      //             color: "text.primary",
      //           }}
      //         >
      //           Sarah Johnson
      //         </Typography>
      //         <Typography
      //           sx={{
      //             fontSize: 12,
      //             fontWeight: 700,
      //             color: "primary.main",
      //           }}
      //         >
      //           sarah@example.com
      //         </Typography>
      //       </Box>
      //     </MenuItem>
      //     <Divider />
      //     <MenuItem>
      //       <ListItemIcon sx={{ color: "text.secondary" }}>
      //         <PersonRoundedIcon fontSize="small" />
      //       </ListItemIcon>
      //       <Typography
      //         sx={{
      //           fontSize: 14,
      //           fontWeight: 400,
      //           color: "text.primary",
      //         }}
      //       >
      //         Profile
      //       </Typography>
      //     </MenuItem>
      //     <MenuItem>
      //       <ListItemIcon sx={{ color: "text.secondary" }}>
      //         <SettingsRoundedIcon fontSize="small" />
      //       </ListItemIcon>
      //       <Typography
      //         sx={{
      //           fontSize: 14,
      //           fontWeight: 400,
      //           color: "text.primary",
      //         }}
      //       >
      //         Setting
      //       </Typography>
      //     </MenuItem>
      //     <MenuItem>
      //       <ListItemIcon sx={{ color: "text.secondary" }}>
      //         <DescriptionRoundedIcon fontSize="small" />
      //       </ListItemIcon>
      //       <Typography
      //         sx={{
      //           fontSize: 14,
      //           fontWeight: 400,
      //           color: "text.primary",
      //         }}
      //       >
      //         Guide
      //       </Typography>
      //     </MenuItem>
      //     <MenuItem>
      //       <ListItemIcon sx={{ color: "text.secondary" }}>
      //         <HelpRoundedIcon fontSize="small" />
      //       </ListItemIcon>
      //       <Typography
      //         sx={{
      //           fontSize: 14,
      //           fontWeight: 400,
      //           color: "text.primary",
      //         }}
      //       >
      //         Help
      //       </Typography>
      //     </MenuItem>
      //     <Divider />
      //     <MenuItem
      //       onClick={handleLogout}
      //     >
      //       <ListItemIcon sx={{ color: "text.secondary" }}>
      //         <KeyboardTabRoundedIcon fontSize="small" />
      //       </ListItemIcon>
      //       <Typography
      //         sx={{
      //           fontSize: 14,
      //           fontWeight: 400,
      //           color: "text.primary",
      //         }}
      //       >
      //         Sign Out
      //       </Typography>
      //     </MenuItem>
      //   </Box>
      // }
      // componentsProps={{
      //   tooltip: {
      //     sx: {
      //       backgroundColor: "background.default",
      //       p: 2,
      //       borderRadius: 2,
      //       boxShadow: 24,
      //       border: `0.0625rem solid #fff`,
      //       maxWidth: "none",
      //       minWidth: 200,
      //     },
      //   },
      //   arrow: {
      //     sx: {
      //       color: "background.paper",
      //     },
      //   },
      // }}
    >
      <Avatar
        alt="account of current user"
        src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cG9ydHJhaXR8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
        sx={{
          width: 20,
          height: 20,
          // marginLeft: { xs: 3, md: 4 },
          cursor: "pointer",
        }}
      />
    </Tooltip>
  );
};

export default UserAvatarIconButton;
