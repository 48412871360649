import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PasswordGuideIconButton from "../PasswordGuideIconButton";

const initialValues = {
  full_name: null,
  email: null,
  contact_num: null,
  password: null,
  confirm_password: null,
  accept_terms_and_policy: false,
};

const SignupSchema = Yup.object().shape({
  full_name: Yup.string().required("Please enter your full name"),
  email: Yup.string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email address"
    )
    .required("Please enter your email address"),
  password: Yup.string()
    .min(8, "Password must have at least 8 characters long")
    .matches(/[0-9]/, "Password must contain at least 1 number")
    .matches(/[!@#$%^&*]/, "Password must contain at least 1 special character")
    .matches(/[A-Z]/, "Password must contain at least 1 uppercase letter")
    .matches(/[a-z]/, "Password must contain at least 1 lowercase letter")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref("password"), undefined],
      "Password and confirm password must be the same"
    )
    .required("Please retype your password"),
  accept_terms_and_policy: Yup.bool().oneOf(
    [true],
    "* You need to agree to the Terms of Service and Privacy Policy"
  ),
});

const SignupForm = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (values: any) => {
    setIsLoading(true);

    setIsLoading(false);

    navigate("/users", { replace: true });
  };

  const handleGoogleSignup = async () => {
    navigate("/users", { replace: true });
  };

  const handleMicrosoftSignup = async () => {
    navigate("/users", { replace: true });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        overflowY: "auto",
        textAlign: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Box
        sx={{
          width: 350,
          maxWidth: "100%",
          paddingTop: 4,
        }}
      >
        <img
          src={"/logo192.png"}
          alt={"loading-logo"}
          loading="lazy"
          width={48}
          height={48}
        />

        <Typography
          sx={{
            textAlign: "center",
            marginBottom: 4,
            fontSize: 20,
            color: "text.primary",
          }}
        >
          Create an account
        </Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={SignupSchema}
          onSubmit={handleFormSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Field id="full_name" name="full_name">
                {(props: any) => (
                  <TextField
                    size="small"
                    fullWidth
                    id="full_name"
                    name="full_name"
                    label="Full Name *"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.full_name ?? ""}
                    placeholder="John Doe"
                    helperText={
                      errors?.full_name &&
                      touched?.full_name &&
                      String(errors?.full_name)
                    }
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      marginBottom: 3,
                    }}
                    error={Boolean(errors?.full_name && touched?.full_name)}
                  />
                )}
              </Field>
              <Field id="email" name="email">
                {(props: any) => (
                  <TextField
                    size="small"
                    fullWidth
                    id="email"
                    name="email"
                    label="Email *"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email ?? ""}
                    placeholder="user@example.com"
                    helperText={
                      errors?.email && touched?.email && String(errors?.email)
                    }
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      marginBottom: 3,
                    }}
                    error={Boolean(errors?.email && touched?.email)}
                  />
                )}
              </Field>
              <Field id="contact_num" name="contact_num">
                {(props: any) => (
                  <TextField
                    size="small"
                    fullWidth
                    id="contact_num"
                    name="contact_num"
                    label="Contact Number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contact_num ?? ""}
                    placeholder="+67 112225555"
                    helperText={
                      errors?.contact_num &&
                      touched?.contact_num &&
                      String(errors?.contact_num)
                    }
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      marginBottom: 3,
                    }}
                    error={Boolean(errors?.contact_num && touched?.contact_num)}
                  />
                )}
              </Field>
              <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "text.primary",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  Guidelines for creating password.
                </Typography>
                <PasswordGuideIconButton
                  iconSize="small"
                  iconColor="primary.main"
                />
              </Box>
              <Field id="password" name="password">
                {(props: any) => (
                  <TextField
                    size="small"
                    fullWidth
                    id="password"
                    name="password"
                    label="Password *"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password ?? ""}
                    placeholder="********"
                    helperText={
                      errors?.password &&
                      touched?.password &&
                      String(errors?.password)
                    }
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      marginBottom: 3,
                    }}
                    type="password"
                    error={Boolean(errors?.password && touched?.password)}
                  />
                )}
              </Field>
              <Field id="confirm_password" name="confirm_password">
                {(props: any) => (
                  <TextField
                    size="small"
                    fullWidth
                    id="confirm_password"
                    name="confirm_password"
                    label="Confirm Password *"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirm_password ?? ""}
                    placeholder="********"
                    helperText={
                      errors?.confirm_password &&
                      touched?.confirm_password &&
                      String(errors?.confirm_password)
                    }
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      marginBottom: 3,
                    }}
                    type="password"
                    error={Boolean(
                      errors?.confirm_password && touched?.confirm_password
                    )}
                  />
                )}
              </Field>
              <FormControlLabel
                id="accept_terms_and_policy"
                name="accept_terms_and_policy"
                checked={values.accept_terms_and_policy}
                onChange={(e) => {
                  setFieldValue(
                    `accept_terms_and_policy`,
                    !values.accept_terms_and_policy
                  );
                }}
                control={
                  <Checkbox
                    sx={{
                      marginTop: -1,
                    }}
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: "text.primary",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    I have read, understood, and agree to the Terms of Service
                    and Privacy Policy stated. *
                  </Typography>
                }
                sx={{
                  alignItems: "flex-start",
                  color: "text.primary",
                  fontSize: 12,
                }}
              />
              <FormHelperText sx={{ color: "error.main", marginLeft: 4 }}>
                {errors?.accept_terms_and_policy &&
                  touched?.accept_terms_and_policy &&
                  String(errors?.accept_terms_and_policy)}
              </FormHelperText>
              <Box sx={{ mt: 1 }}>
                <Typography
                  sx={{
                    fontSize: 12,
                    color: "text.primary",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  Please check the box above to indicate that you have read,
                  understood, and agreed to the{" "}
                  <Link
                    href="#"
                    underline="none"
                    sx={{ color: "primary.main", fontWeight: 600 }}
                  >
                    {"Terms of Service and Privacy Policy"}
                  </Link>{" "}
                  outlined.
                </Typography>
              </Box>
              <Button
                variant="contained"
                fullWidth
                disabled={isLoading || !values.accept_terms_and_policy}
                sx={{
                  backgroundColor: "primary.main",
                  ":hover": { backgroundColor: "primary.main" },
                  marginTop: 2,
                  marginBottom: 3,
                  paddingY: 1,
                }}
                type="submit"
              >
                Get Started
              </Button>
              <Divider>
                <Typography
                  sx={{
                    color: "text.primary",
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  OR
                </Typography>
              </Divider>
              <Button
                variant="outlined"
                fullWidth
                disabled={isLoading}
                sx={{
                  marginTop: 3,
                  marginBottom: 2,
                  paddingY: 1,
                }}
                onClick={handleGoogleSignup}
              >
                <Box
                  component="img"
                  sx={{
                    width: 24,
                    height: 24,
                    display: "block",
                    overflow: "hidden",
                    marginRight: 1,
                  }}
                  src={"/icons/icons8-google-48.png"}
                  alt={`google-signup-button`}
                />
                Continue with Google
              </Button>

              <Button
                variant="outlined"
                disabled={isLoading}
                fullWidth
                sx={{
                  marginBottom: 4,
                  paddingY: 1,
                }}
                onClick={handleMicrosoftSignup}
              >
                <Box
                  component="img"
                  sx={{
                    width: 24,
                    height: 24,
                    display: "block",
                    overflow: "hidden",
                    marginRight: 1,
                  }}
                  src={"/icons/icons8-microsoft-48.png"}
                  alt={`microsoft-signup-button`}
                />
                Continue with Microsoft
              </Button>
              <Box sx={{ paddingBottom: 4 }}>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "text.secondary",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  {"Already have an account? "}
                  <Link
                    href={isLoading ? "#" : "/login"}
                    underline="none"
                    sx={{
                      color: isLoading ? "text.disabled" : "primary.main",
                      fontWeight: 600,
                    }}
                  >
                    {"Login"}
                  </Link>
                </Typography>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default SignupForm;
