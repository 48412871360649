import React from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, Box } from '@mui/material';

interface AddJobButtonProps {
  onClick: () => void; // Explicitly define the type of onClick prop
}

const AddJobButton: React.FC<AddJobButtonProps> = ({ onClick }) => {
  return (
    <Button
      size="small"
      variant="contained"
      startIcon={<AddRoundedIcon />}
      onClick={onClick}
    >
      Add Job
    </Button>
  );
};

export default AddJobButton;
