import { Dispatch, SetStateAction } from "react";
import axios from "axios";
import api from "./api";
import {
  getAccessTokenFromLocalStorage,
  getRefreshTokenFromLocalStorage,
  setTokensToLocalStorage,
} from "../utils/auth";
import { alertError, alertSuccess } from "../utils/alert";
import {
  ChangePasswordContext,
  ResetPasswordContext,
} from "../interfaces/auth";
import { User } from "../interfaces/user";
import { baseAPIUrl } from "../utils/url";

export const signIn = async ({
  username,
  password,
  onSuccess,
  setAlertInfo,
  setUser,
}: {
  username: string;
  password: string;
  onSuccess: () => void;
  setAlertInfo: (value: any) => void;
  setUser: (value: any) => void;
}) => {
  const url = `${baseAPIUrl}/users/jwt/login/`;
  const body = {
    email: username,
    password,
  };

  try {
    const response = await axios.post(url, body);

    if (response.status === 200) {
      const accessToken = response.data["access"];
      const refreshToken = response.data["refresh"];

      setTokensToLocalStorage(accessToken, refreshToken);

      const user = await getUser();
      setUser(user);

      alertSuccess("Welcome!", setAlertInfo);
      onSuccess();
    } else {
      alertError("Failed to login.", setAlertInfo);
    }
  } catch (error: any) {
    alertError(
      error.response.status === 400 &&
        error.response.data.hasOwnProperty("body")
        ? error.response.data.body
        : error.response.data.detail ?? error.toString(),
      setAlertInfo
    );
  }
};

export const getUser = async () => {
  const url = "/users/me/";

  const accessToken = getAccessTokenFromLocalStorage();
  const refreshToken = getRefreshTokenFromLocalStorage();

  if (!accessToken && !refreshToken) return undefined;

  try {
    const response = await api.get(url);
    const user: User = response.data;

    return user;
  } catch (error: any) {
    return undefined;
  }
};

export const getNewAccessToken = async () => {
  const url = `${baseAPIUrl}/users/jwt/refresh/`;

  const refreshToken = getRefreshTokenFromLocalStorage();

  if (!refreshToken) return undefined;

  try {
    const response = await axios.post(url, {
      refresh: refreshToken,
    });

    const accessToken = response.data["access"];
    setTokensToLocalStorage(accessToken);

    return accessToken;
  } catch (error) {
    return undefined;
  }
};

export const triggerForgetPassword = async ({
  email,
  setAlertInfo,
  setIsSubmitted,
}: {
  email: string;
  setAlertInfo: (value: any) => void;
  setIsSubmitted: Dispatch<SetStateAction<boolean>>;
}) => {
  const url = `${baseAPIUrl}/auth-api/users/reset_password/`;

  try {
    const response = await axios.post(url, {
      email,
    });

    if (response.status === 204) {
      setIsSubmitted(true);
    } else {
      alertError("Failed to send reset password email.", setAlertInfo);
    }
    return response;
  } catch (error: any) {
    alertError(error.toString(), setAlertInfo);
  }
};

export const resetPassword = async ({
  resetPasswordContext,
  onSuccess,
  setAlertInfo,
}: {
  resetPasswordContext: ResetPasswordContext;
  onSuccess: () => void;
  setAlertInfo: (value: any) => void;
}) => {
  const url = `${baseAPIUrl}/auth-api/users/reset_password_confirm/`;

  try {
    const response = await axios.post(url, resetPasswordContext);

    if (response.status === 204) {
      alertSuccess("Password reset successfully!", setAlertInfo);
      onSuccess();
    } else {
      alertError("Failed to reset password.", setAlertInfo);
    }
    return response;
  } catch (error: any) {
    alertError(error.toString(), setAlertInfo);
  }
};

export const changePassword = async ({
  changePasswordContext,
  onSuccess,
  setAlertInfo,
}: {
  changePasswordContext: ChangePasswordContext;
  onSuccess: () => void;
  setAlertInfo: (value: any) => void;
}) => {
  const url = `${baseAPIUrl}/users/set_password/`;

  try {
    const response = await api.post(url, changePasswordContext);

    if (response.status === 204) {
      alertSuccess("Password changed successfully!", setAlertInfo);
      onSuccess();
    } else {
      alertError("Failed to change password.", setAlertInfo);
    }
    return response;
  } catch (error: any) {
    if (error.response.data.current_password) {
      alertError(
        `Current Password: ${error.response.data.current_password}`,
        setAlertInfo
      );
    } else if (error.response.data.new_password) {
      alertError(
        `New Password: ${error.response.data.new_password}`,
        setAlertInfo
      );
    } else {
      alertError(error.response.data.detail ?? error.toString(), setAlertInfo);
    }
  }
};

export const getCSV = async (
  setAlertInfo: (value: any) => void
): Promise<string | undefined> => {
  const url = `/auth-api/users/export-full-list`;

  try {
    const response = await api.get(url);

    if (response.status === 200) {
      return response.data;
    } else {
      alertError("Please try again later.", setAlertInfo);
    }
  } catch (error: any) {
    alertError(error.toString(), setAlertInfo);
  }
};
