import {
  Button,
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import { updateUser } from "../../services/users";
import useAlert from "../../hooks/useAlert";

const UserProfileEditSchema = Yup.object().shape({
  full_name: Yup.string().required("Please enter your full name"),
  email: Yup.string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email address"
    )
    .required("Please enter your email address"),
});

const UserProfileEditForm = () => {
  const [isLoading, setisLoading] = useState(false);

  const { user } = useAuth();

  const { setAlertInfo } = useAlert();

  const handleFormSubmit = async (values: any) => {
    setisLoading(true);

    await updateUser({
      userId: values.id,
      body: {
        name: values.full_name,
        email: values.email.toLowerCase(),
      },
      onSuccess: () => {
        
      },
      setAlertInfo,
    });

    setisLoading(false);
  };
  return (
    <Formik
      initialValues={{
        id: user?.id,
        full_name: user?.name,
        email: user?.email,
      }}
      validationSchema={UserProfileEditSchema}
      onSubmit={handleFormSubmit}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Field id="full_name" name="full_name">
            {(props: any) => (
              <TextField
                variant="standard"
                size="small"
                fullWidth
                id="full_name"
                name="full_name"
                label="Full Name *"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.full_name ?? ""}
                placeholder="John Doe"
                helperText={
                  errors?.full_name &&
                  touched?.full_name &&
                  String(errors?.full_name)
                }
                InputLabelProps={{ shrink: true }}
                sx={{
                  marginBottom: 3,
                }}
                error={Boolean(errors?.full_name && touched?.full_name)}
              />
            )}
          </Field>
          <Field id="email" name="email">
            {(props: any) => (
              <TextField
                variant="standard"
                size="small"
                fullWidth
                id="email"
                name="email"
                label="Email *"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email ?? ""}
                placeholder="user@example.com"
                helperText={
                  errors?.email && touched?.email && String(errors?.email)
                }
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
                sx={{
                  marginBottom: 3,
                }}
                error={Boolean(errors?.email && touched?.email)}
              />
            )}
          </Field>

          <Button
            variant="contained"
            fullWidth
            disabled={isLoading}
            sx={{
              backgroundColor: "primary.main",
              ":hover": { backgroundColor: "primary.main" },
              marginTop: 2,
              paddingY: 1,
            }}
            type="submit"
          >
            Save
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default UserProfileEditForm;
