import AuthProvider from "./contexts/auth.context";
import Providers from "./providers";

function App() {
  return <AuthProvider>
    <Providers />
  </AuthProvider>
}

export default App;
