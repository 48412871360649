import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import useAlert from "../../hooks/useAlert";
import { alertSuccess } from "../../utils/alert";
import { User } from "../../interfaces/user";
import { updateUser } from "../../services/users";

type EditUserDialogProps = {
  user: User;
  open: boolean;
  handleClose: () => void;
  mutate: () => void;
};

const EditUserSchema = Yup.object().shape({
  full_name: Yup.string().required("Please enter contact full name"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter contact email address"),
});

const EditUserDialog = React.forwardRef<HTMLDivElement, EditUserDialogProps>(
  function EditUserDialog(props, ref) {
    const { user, open, handleClose, mutate } = props;

    const { setAlertInfo } = useAlert();

    const [isLoading, setisLoading] = useState(false);

    const handleFormSubmit = async (values: any) => {
      setisLoading(true);
  
      await updateUser({
        userId: values.id,
        body: {
          name: values.full_name,
          email: values.email.toLowerCase(),
        },
        onSuccess: () => {
          mutate();
          handleClose();
        },
        setAlertInfo,
      });
  
      setisLoading(false);
    };
    return (
      <Modal ref={ref} open={open}>
        <div>
          <Formik
            initialValues={{
              id: user.id,
              full_name: user.name,
              email: user.email,
            }}
            validationSchema={EditUserSchema}
            onSubmit={handleFormSubmit}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: "80%",
                    width: 350,
                    backgroundColor: "background.paper",
                    borderRadius: 2,
                    paddingX: 4,
                    paddingY: 4,
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box sx={{ marginBottom: 2, display: "flex" }}>
                    <Typography variant="h6" sx={{ color: "text.primary" }}>
                      Edit User
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      mt: 4,
                      mb: 5,
                      display: "flex",
                      flexDirection: "column",
                      gap: 3,
                    }}
                  >
                    <Field id="full_name" name="full_name">
                      {({}) => (
                        <TextField
                          variant="standard"
                          size="small"
                          fullWidth
                          id="full_name"
                          name="full_name"
                          label="Full Name *"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.full_name}
                          placeholder="e.g. John Doe"
                          InputLabelProps={{ shrink: true }}
                          helperText={
                            errors?.full_name &&
                            touched?.full_name &&
                            String(errors?.full_name)
                          }
                          error={Boolean(
                            errors?.full_name && touched?.full_name
                          )}
                        />
                      )}
                    </Field>
                    <Field id="email" name="email">
                      {({}) => (
                        <TextField
                          variant="standard"
                          size="small"
                          fullWidth
                          id="email"
                          name="email"
                          label="Email *"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          placeholder="user@example.com"
                          InputLabelProps={{ shrink: true }}
                          helperText={
                            errors?.email &&
                            touched?.email &&
                            String(errors?.email)
                          }
                          error={Boolean(errors?.email && touched?.email)}
                        />
                      )}
                    </Field>
                  </Box>

                  <Box sx={{ display: "flex", marginTop: 2 }}>
                    <Button
                      fullWidth
                      size="small"
                      disabled={isLoading}
                      variant="outlined"
                      disableElevation
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Box width={32} />
                    <Button
                      fullWidth
                      size="small"
                      disabled={isLoading}
                      variant="contained"
                      disableElevation
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    );
  }
);

export default EditUserDialog;
