import { Box, Button, Modal, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import useAlert from "../../hooks/useAlert";
import { Job } from "../../interfaces/jobs";
import { alertSuccess } from "../../utils/alert";
import { deleteJob } from "../../services/jobs";

type DeleteJobDialogProps = {
  job: Job;
  open: boolean;
  handleClose: () => void;
};

const DeleteJobDialog = React.forwardRef<HTMLDivElement, DeleteJobDialogProps>(
  function DeleteJobDialog(props, ref) {
    const { job, open, handleClose } = props;

    const { setAlertInfo } = useAlert();

    const [isLoading, setisLoading] = useState(false);

    const handleFormSubmit = async (values: any) => {
      setisLoading(true);
      await deleteJob({
        jobId: values.id,
        onSuccess: () => {
          setisLoading(false);
          handleClose();
        },
        setAlertInfo,
      });
      alertSuccess("Delete job success.", setAlertInfo);
      setisLoading(false);
      handleClose();
    };
    return (
      <Modal ref={ref} open={open}>
        <div>
          <Formik
            initialValues={{
              id: job.id,
            }}
            onSubmit={handleFormSubmit}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {({ handleSubmit }) => (
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: "80%",
                    width: 350,
                    backgroundColor: "background.paper",
                    borderRadius: 2,
                    paddingX: 4,
                    paddingY: 4,
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box sx={{ marginBottom: 2, display: "flex" }}>
                    <Typography variant="h6" sx={{ color: "text.primary" }}>
                      Delete Job
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      mt: 4,
                      mb: 5,
                      display: "flex",
                      flexDirection: "column",
                      gap: 1.5,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: "text.primary",
                        textAlign: "left",
                        fontWeight: 500,
                      }}
                    >
                      Are you absolutely certain you wish to proceed with
                      deleting this job from our records?
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        color: "text.secondary",
                        textAlign: "left",
                        fontWeight: 500,
                      }}
                    >
                      Please ensure that this deletion aligns with company
                      policies and guidelines before continuing.
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", marginTop: 2 }}>
                    <Button
                      fullWidth
                      size="small"
                      disabled={isLoading}
                      variant="outlined"
                      disableElevation
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Box width={32} />
                    <Button
                      fullWidth
                      size="small"
                      disabled={isLoading}
                      variant="contained"
                      color="error"
                      disableElevation
                      type="submit"
                      sx={{ borderColor: "error.main" }}
                    >
                      Confirm Delete
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    );
  }
);

export default DeleteJobDialog;
