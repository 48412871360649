import useSWR from "swr";
import api from "./api";
import { createFetcher } from "./fetcher";
import { InstalledProduct } from "../interfaces/installedProduct";
import { APIWithPagination } from "../interfaces/pagination";

export const useAllInstalledProducts = ({
  page = 0,
  pageSize = 25,
  search,
  ordering,
}: APIWithPagination & { isTenant?: boolean }) => {
  let url = `/installed-products`;

  url += `?page=${page + 1}&page_size=${pageSize}`;

  if (search !== undefined) {
    url += `&search=${search}`;
  }

  if (ordering !== undefined) {
    url += `&ordering=${ordering}`;
  }

  const snapshot = useSWR<{
    count: number;
    next: string;
    previous: string;
    results: InstalledProduct[];
    total_pages: number;
  }>(url, createFetcher, {
    revalidateOnMount: true,
  });

  return { ...snapshot };
};

export const proceedInstalledProduct = (
    id: number,
    ) => {
    const url = `/installed-products/${id}/proceed/`;

    return api.put(url);
};

export const cancelInstalledProduct = (
    id: number,
    ) => {
    const url = `/installed-products/${id}/cancel/`;

    return api.put(url);
};

export const updateInstalledProduct = (
  id: number,
  data: Partial<InstalledProduct>
) => {
  const url = `/installed-products/${id}/update/`;

  return api.post(url, data);
}

export const addInstalledProducts = null;

export const deleteInstalledProduct = null;
