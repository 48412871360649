export const alertError = (err: string, setAlertInfo: (value: any) => void) => {
    setAlertInfo((prev: any) => ({
      ...prev,
      open: true,
      severity: "error",
      msg: err,
    }));
};

export const alertSuccess = (
    msg: string,
    setAlertInfo: (value: any) => void
  ) => {
    setAlertInfo((prev: any) => ({
      ...prev,
      open: true,
      severity: "success",
      msg: msg,
    }));
};
  