import { Box, Button, TextField, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import PasswordGuideIconButton from "../PasswordGuideIconButton";
import { changePassword } from "../../services/auth";
import { ChangePasswordContext } from "../../interfaces/auth";
import useAlert from "../../hooks/useAlert";

const initialValues = {
  current_password: null,
  new_password: null,
  confirm_password: null,
};

const ChangePasswordSchema = Yup.object().shape({
  current_password: Yup.string()
    // .min(8, "Password must have at least 8 characters long")
    // .matches(/[0-9]/, "Password must contain at least 1 number")
    // .matches(/[!@#$%^&*]/, "Password must contain at least 1 special character")
    // .matches(/[A-Z]/, "Password must contain at least 1 uppercase letter")
    // .matches(/[a-z]/, "Password must contain at least 1 lowercase letter")
    .required("Current password is required"),
  new_password: Yup.string()
    .min(8, "Password must have at least 8 characters long")
    .matches(/[0-9]/, "Password must contain at least 1 number")
    .matches(/[!@#$%^&*]/, "Password must contain at least 1 special character")
    .matches(/[A-Z]/, "Password must contain at least 1 uppercase letter")
    .matches(/[a-z]/, "Password must contain at least 1 lowercase letter")
    .required("New password is required"),
  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref("new_password"), undefined],
      "New Password and confirm password must be the same"
    )
    .required("Please retype your new password"),
});

const ChangePasswordForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setAlertInfo } = useAlert();

  const handleFormSubmit = async (values: any) => {
    setIsLoading(true);

    await changePassword({
      changePasswordContext: {
        current_password: values.current_password,
        new_password: values.new_password,
        re_new_password: values.re_new_password,
      } as ChangePasswordContext,
      onSuccess: () => {
        const callbackUrl = "/";
      },
      setAlertInfo,
    });

    setIsLoading(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6">Change Password</Typography>
        <PasswordGuideIconButton iconSize="medium" iconColor="primary.main" />
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={ChangePasswordSchema}
        onSubmit={handleFormSubmit}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Field id="current_password" name="current_password">
              {(props: any) => (
                <TextField
                  variant="standard"
                  size="small"
                  fullWidth
                  id="current_password"
                  name="current_password"
                  label="Current Password *"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.current_password ?? ""}
                  placeholder="********"
                  helperText={
                    errors?.current_password &&
                    touched?.current_password &&
                    String(errors?.current_password)
                  }
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    marginBottom: 3,
                  }}
                  type="password"
                  error={Boolean(
                    errors?.current_password && touched?.current_password
                  )}
                />
              )}
            </Field>
            <Field id="new_password" name="new_password">
              {(props: any) => (
                <TextField
                  variant="standard"
                  size="small"
                  fullWidth
                  id="new_password"
                  name="new_password"
                  label="New Password *"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.new_password ?? ""}
                  placeholder="********"
                  helperText={
                    errors?.new_password &&
                    touched?.new_password &&
                    String(errors?.new_password)
                  }
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    marginBottom: 3,
                  }}
                  type="password"
                  error={Boolean(errors?.new_password && touched?.new_password)}
                />
              )}
            </Field>
            <Field id="confirm_password" name="confirm_password">
              {(props: any) => (
                <TextField
                  variant="standard"
                  size="small"
                  fullWidth
                  id="confirm_password"
                  name="confirm_password"
                  label="Re-Enter New Password *"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirm_password ?? ""}
                  placeholder="********"
                  helperText={
                    errors?.confirm_password &&
                    touched?.confirm_password &&
                    String(errors?.confirm_password)
                  }
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    marginBottom: 3,
                  }}
                  type="password"
                  error={Boolean(
                    errors?.confirm_password && touched?.confirm_password
                  )}
                />
              )}
            </Field>
            <Button
              variant="contained"
              fullWidth
              disabled={isLoading}
              sx={{
                backgroundColor: "primary.main",
                ":hover": { backgroundColor: "primary.main" },
                marginTop: 2,
                paddingY: 1,
              }}
              type="submit"
            >
              Confirm
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ChangePasswordForm;
