import { Box } from "@mui/material";
import ForgetPasswordForm from "../components/ForgetPassword/ForgetPasswordForm";
import PageTitle from "../components/PageTitle";

const ForgetPasswordPage = () => {
  return (
    <>
      <PageTitle title="Forget Password"/>
      <Box
        sx={{
          flex: 1,
          display: { xs: "none", md: "flex" },
          background: "linear-gradient(to right, #5570F1, #375AA2)",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={"/images/login/landing-image.png"}
          alt={"login-landing-pic"}
          loading="lazy"
          width={300}
          height={300}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          width: "100%",
          display: "flex",
          bgcolor: "background.default",
          alignItems: "center",
        }}
      >
        <ForgetPasswordForm />
      </Box>
    </>
  );
};

export default ForgetPasswordPage;
