import useSWR from "swr";
import api from "./api";
import { createFetcher } from "./fetcher";
import { Contract } from "../interfaces/contract";
import { APIWithPagination } from "../interfaces/pagination";

export const useAllContracts = ({
  page = 0,
  pageSize = 25,
  search,
  ordering,
}: APIWithPagination & { isTenant?: boolean }) => {
  let url = `/contracts`;

  url += `?page=${page + 1}&page_size=${pageSize}`;

  if (search !== undefined) {
    url += `&search=${search}`;
  }

  if (ordering !== undefined) {
    url += `&ordering=${ordering}`;
  }

  const snapshot = useSWR<{
    count: number;
    next: string;
    previous: string;
    results: Contract[];
    total_pages: number;
  }>(url, createFetcher, {
    revalidateOnMount: true,
  });

  return { ...snapshot };
};

export const proceedContract = (
    id: number,
    ) => {
    const url = `/contracts/${id}/proceed/`;

    return api.put(url);
};

export const cancelContract = (
    id: number,
    ) => {
    const url = `/contracts/${id}/cancel/`;

    return api.put(url);
};

export const addContracts = null;

export const updateContract = null;

export const deleteContract= null;
