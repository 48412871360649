import moment from "moment";

export const getDateForFileName = (): string => moment().format("YYYYMMDD");

export const DAYS_BEFORE_EXP = 30;

export const DATE_FORMAT = "DD/MM/YYYY";
const TIME_FORMAT = "hh:mm A";

export const MAX_DATE = "31/12/9999";

export const formatDate = (date: string): string =>
  moment(date).format(DATE_FORMAT);

export const formatDateTime = (date: string): string =>
  moment(date).format(`${DATE_FORMAT}, ${TIME_FORMAT}`);

export const fromNow = (date: string): string => moment(date).fromNow();

export const futureDateFromNow = (date: string) => {
  const futureMoment = moment(date);
  const now = moment();

  if (!futureMoment.isAfter(now)) return "";

  const yearsLeft = futureMoment.diff(now, "years");
  futureMoment.subtract(yearsLeft, "years");

  const monthsLeft = futureMoment.diff(now, "months");
  futureMoment.subtract(monthsLeft, "months");

  const daysLeft = futureMoment.diff(now, "days");
  futureMoment.subtract(daysLeft, "days");

  const hoursLeft = futureMoment.diff(now, "hours");
  futureMoment.subtract(hoursLeft, "hours");

  const minutesLeft = futureMoment.diff(now, "minutes");

  let timeLeftString = "";

  if (yearsLeft > 0) {
    timeLeftString += `${yearsLeft} year${yearsLeft === 1 ? "" : "s"}, `;
  }
  if (monthsLeft > 0) {
    timeLeftString += `${monthsLeft} month${monthsLeft === 1 ? "" : "s"}, `;
  }
  if (daysLeft > 0) {
    timeLeftString += `${daysLeft} day${daysLeft === 1 ? "" : "s"}, `;
  }
  if (hoursLeft > 0) {
    timeLeftString += `${hoursLeft} hour${hoursLeft === 1 ? "" : "s"}, `;
  }
  if (minutesLeft > 0) {
    timeLeftString += `${minutesLeft} minute${minutesLeft === 1 ? "" : "s"}`;
  }

  return timeLeftString.trim().replace(/,\s*$/, "") + " left";
};

export const formatUserDate = (date: string): string =>
  moment(date).format("DD MMM YYYY");
