import { baseAPIUrl } from "../utils/url";
import api from "./api";
import axios from "axios";

export const createFetcher = (url: string) =>
  api.get(url).then((res) => {
    try {
      if (res.status === 200) {
        return res?.data;
      } else {
        const error = new Error("An error occurred while fetching the data.");

        error.message = res?.data?.message;
        error.cause = res?.status;

        throw error;
      }
    } catch (error) {
      throw error;
    }
  });

export const createPublicFetcher = (url: string) =>
  axios.get(baseAPIUrl + url).then((res) => {
    try {
      if (res.status === 200) {
        return res?.data;
      } else {
        const error = new Error("An error occurred while fetching the data.");

        error.message = res?.data?.message;
        error.cause = res?.status;

        throw error;
      }
    } catch (error) {
      throw error;
    }
  });
