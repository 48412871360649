import { GridColDef, GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import { PaginationModel } from "../../interfaces/pagination";
import { Contract } from "../../interfaces/contract";
import Table, { Text } from "../Table";
import { formatDateTime } from "../../utils/date.format.utils";
import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { proceedContract, cancelContract } from "../../services/contracts";

const ContractsTable = ({
  contracts,
  rowCount,
  paginationModel,
  setPaginationModel,
  handleFilterModelChange,
  handleSortModelChange,
  isLoading,
  refreshContracts
}: {
  contracts: Contract[];
  rowCount: number;
  paginationModel: PaginationModel;
  setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModel>>;
  handleFilterModelChange: (filterModel: GridFilterModel) => void;
  handleSortModelChange: (sortModel: GridSortModel) => void;
  isLoading: boolean;
  refreshContracts: () => void;
}) => {
  const TRUCATE_LENGTH = 100;
  const [showAll, setShowAll] = useState<Map<string, boolean>>(new Map());

  useEffect(() => {
    // Initialize showAll state with contracts id as key and false as value
    const showAllMap = new Map<string, boolean>();
    contracts.forEach((contract) => {
      if (contract.id) {
        showAllMap.set(contract.id.toString(), false);
      }
    });
    setShowAll(showAllMap);
  }, [contracts]);

  const buttonStyle = {
    minWidth: '160px',
    maxWidth: '160px',
  };

  const truncate = (str: string, index: number) => {
    return str.length > TRUCATE_LENGTH ? (
      <div>
        <Text
          text={
            showAll.get(index.toString())
              ? str
              : str.substring(0, TRUCATE_LENGTH) + "..."
          }
        />
        <Button
          onClick={() => {
            setShowAll(
              new Map(
                showAll.set(index.toString(), !showAll.get(index.toString()))
              )
            );
          }}
          size="small"
        >
          {showAll.get(index.toString()) ? "Show less" : "Show more"}
        </Button>
      </div>
    ) : (
      <Text text={str || "-"} />
    );
  };

  const updateContractStatus = (id: number) => {
    proceedContract(id);
    // wait for 1 second to refresh the data
    setTimeout(() => {
      refreshContracts();
    }, 1000);
  }

  const cancelContractStatus = (id: number) => {
    cancelContract(id);
    // wait for 1 second to refresh the data
    setTimeout(() => {
      refreshContracts();
    }, 1000);
  }

  const columns: GridColDef<Contract>[] = [
    {
      field: "index",
      headerName: "No.",
      sortable: false,
      disableColumnMenu: true,
      width: 45,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <Text text={`${params.value}.`} />,
    },
    {
      field: "contract_id",
      headerName: "Contract ID",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "contract_name",
      headerName: "Name",
      minWidth: 150,
      flex: 4,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "company_name",
      headerName: "Company",
      minWidth: 150,
      flex: 2,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "quote_number",
      headerName: "Quote Number",
      sortable: false,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Text text={params.value || "-"} />,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      minWidth: 170,
      flex: 2,
      renderCell: (params) => {
        if (params.value) {
          return <Button variant="contained" size="small" style={buttonStyle} onClick={() => updateContractStatus(params.row.contract_id)}>{params.value}</Button>;
        } else {
          return <Button variant="contained" disabled style={buttonStyle} size="small">N/A</Button>;
        }
      },
    },
    {
      field: "is_cancelled",
      headerName: "",
      sortable: false,
      minWidth: 150,
      flex: 2,
      renderCell: (params) => {
        if (params.value === true) {
          return <Button variant="contained" size="small" color='warning' disabled >Cancel</Button>; 
        } else {
        return <Button variant="contained" size="small" style={{ border: '1px solid #ed6c02' }} color='warning' onClick={() => cancelContractStatus(params.row.contract_id)}>Cancel</Button>
        }
      },
    }
    // {
    //   field: "job",
    //   headerName: "Job",
    //   minWidth: 150,
    //   flex: 1,
    //   renderCell: (params) => <Text text={params.value || "-"} />,
    // },
  ];

  return (
    <>
      <Table
        rows={contracts.map((contract, index) => ({
          ...contract,
          index: paginationModel.page * paginationModel.pageSize + (index + 1),
        }))}
        columns={columns}
        loading={isLoading}
        sx={{
          display: "grid",
          ".css-axafay-MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
        }}
        autoHeight={false}
        rowCount={rowCount}
        pageSizeOptions={[25, 50, 100]}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        filterMode="server"
        onFilterModelChange={handleFilterModelChange}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
      />
    </>
  );
};

export default ContractsTable;
