import {
  getAccessTokenFromLocalStorage,
  setLoginRedirectPathname,
} from "../../utils/auth";
import React, { FC, PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthenticationWrapper: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const loggedIn = getAccessTokenFromLocalStorage();

  useEffect(() => {
    if (!!!loggedIn) {
      const redirectPath = window.location.pathname;
      setLoginRedirectPathname(redirectPath);
      //! Navigate to login page when there is no token
      navigate("/login");
    }
  }, [loggedIn, navigate]);

  return <>{children}</>;
};

export default AuthenticationWrapper;
