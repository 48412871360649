import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import useAlert from "../../hooks/useAlert";
import { alertSuccess } from "../../utils/alert";
import { User } from "../../interfaces/user";
import { updateUser } from "../../services/users";
import ProfileInformationListItem from "./ProfileInformationListItem";

type ViewUserDialogProps = {
  user: User;
  open: boolean;
  handleClose: () => void;
};

const ViewUserDialog = React.forwardRef<HTMLDivElement, ViewUserDialogProps>(
  function EditUserDialog(props, ref) {
    const { user, open, handleClose } = props;

    const { setAlertInfo } = useAlert();

    const [isLoading, setisLoading] = useState(false);

    const handleFormSubmit = async (values: any) => {
      setisLoading(true);

      await updateUser({
        userId: values.id,
        body: {
          name: values.full_name,
          email: values.email.toLowerCase(),
        },
        onSuccess: () => {
          handleClose();
        },
        setAlertInfo,
      });

      setisLoading(false);
    };
    return (
      <Modal ref={ref} open={open}>
        <div>
          <Formik
            initialValues={{
              id: user.id,
              full_name: user.name,
              email: user.email,
            }}
            onSubmit={handleFormSubmit}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {({
              values,
            }) => (

              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  maxWidth: "80%",
                  width: 350,
                  backgroundColor: "background.paper",
                  borderRadius: 2,
                  paddingX: 4,
                  paddingY: 4,
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Box sx={{ marginBottom: 2, display: "flex" }}>
                  <Typography variant="h6" sx={{ color: "text.primary" }}>
                    View User
                  </Typography>
                </Box>

                <ProfileInformationListItem label={"Full Name"} text={values.full_name || "-"} />
                <ProfileInformationListItem label={"Email"} text={values.email || "-"} />
                <Box sx={{ display: "flex", marginTop: 2 }}>
                  <Button
                    fullWidth
                    size="small"
                    disabled={isLoading}
                    variant="outlined"
                    disableElevation
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            )}
          </Formik>
        </div>
      </Modal>
    );
  }
);

export default ViewUserDialog;
