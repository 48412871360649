import useSWR from "swr";
import api from "./api";
import { createFetcher } from "./fetcher";
// import { CustomCreateJob } from "../interfaces/auth";
import { Job } from "../interfaces/jobs";
import { alertSuccess, alertError } from "../utils/alert";
import { APIWithPagination } from "../interfaces/pagination";

export const useAllJobs = ({
  page = 0,
  pageSize = 25,
  search,
  ordering,
}: APIWithPagination & { isTenant?: boolean }) => {
  let url = `/jobs/jobs`;

  url += `?page=${page + 1}&page_size=${pageSize}`;

  if (search !== undefined) {
    url += `&search=${search}`;
  }

  if (ordering !== undefined) {
    url += `&ordering=${ordering}`;
  }

  const snapshot = useSWR<{
    count: number;
    next: string;
    previous: string;
    results: Job[];
    total_pages: number;
  }>(url, createFetcher, {
    revalidateOnMount: true,
  });

  return { ...snapshot };
};

export const addJobs = async ({
  jobs,
  onSuccess,
  setAlertInfo,
}: {
  jobs: Job[];
  onSuccess: () => void;
  setAlertInfo: (value: any) => void;
}) => {
  const url = `/jobs/jobs/`;

  try {
    await api.post(url, jobs);

    alertSuccess(`Job${jobs.length > 1 ? "s" : ""} added!`, setAlertInfo);
    onSuccess();
  } catch (error: any) {
    alertError(
      error?.response?.status === 401
        ? "Please login first."
        : error.response.data?.[0].email?.[0] ??
            error.response.data.detail ??
            error.response.data.message ??
            error.toString(),
      setAlertInfo
    );
  }
};

export const updateJob = async ({
  jobId,
  body,
  onSuccess,
  setAlertInfo,
}: {
  jobId: Job;
  body: Partial<Job>;
  onSuccess: () => void;
  setAlertInfo: (value: any) => void;
}) => {
  const url = `/jobs/jobs/${jobId}/`;

  try {
    await api.patch(url, body);

    alertSuccess(`Job "${body.name}" updated!`, setAlertInfo);
    onSuccess();
  } catch (error: any) {
    alertError(
      error?.response?.status === 401
        ? "Please login first."
        : error.response.data.detail ??
            error.response.data.message ??
            error.toString(),
      setAlertInfo
    );
  }
};

export const deleteJob = async ({
  jobId,
  onSuccess,
  setAlertInfo,
}: {
  jobId: number;
  onSuccess: () => void;
  setAlertInfo: (value: any) => void;
}) => {
  const url = `/jobs/jobs/${jobId}/`;

  try {
    await api.delete(url);

    alertSuccess("Job deleted", setAlertInfo);
    onSuccess();
  } catch (error: any) {
    alertError(
      error?.response?.status === 401
        ? "Please login first."
        : error.response.data.detail ??
            error.response.data.message ??
            error.toString(),
      setAlertInfo
    );
  }
};
